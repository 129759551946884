export const roles = [
  {
    id: 101,
    label: "Admin",
    name: "admin",
    info: "Admin can control the entire platform.",
  },
  {
    id: 102,
    label: "Admin (read only)",
    name: "readonlyAdmin",
    info: "Admin can view the entire platform without able to create/edit items.",
  },
  {
    id: 103,
    label: "Outlet manager",
    name: "outletManager",
    info: "This user can manage one or more outlets. ",
  },
  {
    id: 104,
    label: "Outlet manager (read only)",
    name: "readonlyOutletManager",
    info: "This user can view one or more outlets  without able to create/edit items. ",
  },
];

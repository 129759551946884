import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, ListItemButton, ListItemText } from "@mui/material";
import Popover from "@mui/material/Popover";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteCashbook } from "../../../redux/cashbook/cashbookSlice";
import { deletePettyCash } from "../../../redux/pettycashes/pettycashesSlice";
import { getAllCategory } from "../../../redux/productCategory/productCategorySlice";
import { removeSale } from "../../../redux/sales/salesSlice";
import {
  deleteStockUsage,
  updateStockUsage,
} from "../../../redux/stockUsage/stockUsageSlice";
import { removeStock } from "../../../redux/stocks/stocksSlice";
import CustomModal from "../../common/CustomModal";
import DeleteConfirmation from "../User/DeleteConfirmation";
import AddBill from "./Bills/AddBill";
import PettyCashForm from "./PettyCashes/PettyCashForm";
import EditSale from "./Sales/EditSale";
import AddStockUsages from "./Stocks/AddStockUsages";
import EditStock from "./Stocks/EditStock";
import { deleteBill } from "../../../redux/bills/billsSlice";

export default function ActionMenu({
  id,
  item,
  handleSubmit,
  selectedServices,
  setSelectedServices,
  title,
  edit = false,
}) {
  const dispatch = useDispatch();
  const { categories } = useSelector((state) => state.categories);
  const [anchorEl, setAnchorEl] = useState(null);
  const { allSuppliers } = useSelector((state) => state.suppliers);
  // const [purchasePhoto, setPurchasePhoto] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    getAllCategory();
  }, [dispatch]);

  const open = Boolean(anchorEl);
  const ids = open ? "simple-popover" : undefined;
  const deleteHandler = (id) => {
    if (title === "Sales") dispatch(removeSale(id));
    if (title === "Purchase") dispatch(deleteBill(id));
    if (title === "Stock Usage") dispatch(deleteStockUsage(id));
    if (title === "Petty Cash") dispatch(deletePettyCash(id));
    if (title === "Cashbook") dispatch(deleteCashbook(id));
    if (title === "Stock")
      dispatch(
        removeStock({
          outlet: id,
          id: item._id,
          product: item.product?._id,
          date: item.date,
        })
      );
  };

  const handleEdit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const formData = {
      date: data.get("date"),
      product: data.get("product"),
      quantity: data.get("quantity"),
      price: data.get("price"),
      outlet: id,
    };
    dispatch(updateStockUsage({ id: item._id, data: formData }));
  };

  return (
    <div>
      <IconButton
        aria-describedby={ids}
        variant="contained"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={ids}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ minWidth: 170 }}>
          {edit && (
            <CustomModal
              title={`Edit ${title}`}
              action={
                <ListItemButton sx={{ px: 2, py: 1, fontWeight: 700 }}>
                  Edit
                </ListItemButton>
              }
            >
              {title === "Stock Usage" && (
                <AddStockUsages handleSubmit={handleEdit} item={item} edit />
              )}
              {title === "Sales" && <EditSale item={item} />}
              {title === "Purchase" && (
                <AddBill suppliers={allSuppliers} bill={item} edit />
              )}
              {title === "Stock" && <EditStock item={item} />}

              {title === "Petty Cash" && (
                <PettyCashForm pettycash={item} categories={categories} edit />
              )}
            </CustomModal>
          )}

          <CustomModal
            title={"Are you sure to delete?"}
            sx={{ maxWidth: 400, borderRadius: "0px" }}
            action={
              <ListItemButton>
                <ListItemText sx={{ color: "error.main" }}>
                  Remove List
                </ListItemText>
              </ListItemButton>
            }
          >
            <DeleteConfirmation deleteHandler={() => deleteHandler(id)} />
          </CustomModal>
        </Box>
      </Popover>
    </div>
  );
}

import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOpeningStock } from "../../../../redux/stocks/stocksAPI";
import { addStocks } from "../../../../redux/stocks/stocksSlice";
import PrimaryButton from "../../../common/PrimaryButton";

const AddStocks = ({ outlet }) => {
  const dispatch = useDispatch();
  const [date] = useState(new Date().toISOString().split("T")[0]);
  const { allProducts } = useSelector((state) => state.products);
  const allOptions = useMemo(
    () =>
      allProducts?.map(({ _id, name, unit }) => ({ product: _id, name, unit })),
    [allProducts]
  );
  const [options, setOptions] = useState(
    JSON.parse(JSON.stringify(allOptions))
  );

  const [rows, setRows] = useState(
    JSON.parse(localStorage.getItem("stocks") || "[]")
  );

  const handleProducts = (_e, newValue) => {
    const rowsData = [...newValue.map((item) => ({ ...item, outlet, date }))];
    // Filter data in selection
    const optionData = [
      ...allOptions.filter(
        (item) => !newValue.find(({ product }) => item.product === product)
      ),
    ];
    setRows(rowsData);
    setOptions(optionData);
    localStorage.setItem("stocks", JSON.stringify(rowsData));
  };
  const handleItem = (e, index) => {
    const newRows = [...rows];
    newRows[index][e.target.name] = e.target.value;
    localStorage.setItem("stocks", JSON.stringify(newRows));
  };

  const removeItem = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);

    setRows(newRows);
    const optionData = [
      ...allOptions.filter(
        (item) => !newRows.some(({ product }) => product === item.product)
      ),
    ];
    setOptions(optionData);
    // If selection row length is 0, remove it
    if (newRows.length === 0) localStorage.removeItem("stocks");
    else localStorage.setItem("stocks", JSON.stringify(newRows));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const dates = formData.getAll("date");
    const names = formData.getAll("name");
    const products = formData.getAll("product");
    const openingStocks = formData.getAll("openingStock");
    const closingStocks = formData.getAll("closingStock");
    const data = dates.map((date, i) => ({
      date,
      name: names[i],
      product: products[i],
      outlet,
      openingStock: openingStocks[i],
      closingStock: closingStocks[i],
    }));
    dispatch(addStocks({ stocks: data }));
    // After add stock remove the selection of localstorage
    localStorage.removeItem("stocks");
  };

  useEffect(() => {
    // If have previousStock in localstorage, filter the selectedPreviousStock in selection
    if (localStorage.getItem("stocks")) {
      const previousStock = JSON.parse(localStorage.getItem("stocks"));

      const optionData = [
        ...allOptions.filter(
          (item) =>
            !previousStock.some(({ product }) => product === item.product)
        ),
      ];
      setOptions(optionData);
    }
  }, []);

  return (
    <Box sx={{ p: 3 }} component={"form"} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
            Select Products
          </Typography>
          <Autocomplete
            size="small"
            multiple
            id="tags-outlined"
            value={rows}
            onChange={handleProducts}
            options={options || []}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                // label="filterSelectedOptions"
                placeholder="choose product"
              />
            )}
          />
        </Grid>
      </Grid>
      <TableContainer>
        <Table stickyHeader aria-labelledby="tableTitle">
          <TableHead sx={{ position: "sticky", top: 0, zIndex: 10 }}>
            <TableRow>
              <TableCell>Date </TableCell>
              <TableCell>Product </TableCell>
              <TableCell>Unit </TableCell>
              <TableCell>Opening Stock </TableCell>
              <TableCell>Closing Stock </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <SingleStock
                outlet={outlet}
                key={row._id}
                row={row}
                dateValue={date}
                index={index}
                handleItem={handleItem}
                removeItem={removeItem}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ textAlign: "right", mt: 3 }}>
        <PrimaryButton
          sx={{ width: 150 }}
          type="submit"
          disabled={rows.length === 0}
        >
          Add Stocks
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default AddStocks;

const SingleStock = ({
  row = {},
  handleItem,
  dateValue,
  outlet,
  index,
  removeItem,
}) => {
  const { units } = useSelector((state) => state.units);
  const [date, setDate] = useState(dateValue);
  const [openingStock, setOpeningStock] = useState(0);
  useEffect(() => {
    getOpeningStock({ date, product: row.product, outlet }).then((res) => {
      if (res.data?.length > 0) {
        setOpeningStock(res.data?.[0]?.closingStock);
      } else {
        setOpeningStock(0);
      }
    });
  }, [row.product, date, outlet]);
  return (
    <TableRow hover tabIndex={-1}>
      <TableCell sx={{ py: "5px" }}>
        <TextField
          onChange={(e) => setDate(e.target.value)}
          value={date}
          type="date"
          required
          size="small"
          inputProps={{ min: new Date().toLocaleDateString("en-CA") }}
          name="date"
        />
      </TableCell>
      <TableCell sx={{ py: "5px" }}>
        {row.name}
        <input type="hidden" name="name" value={row.name} />
        <input type="hidden" name="product" value={row.product} />
      </TableCell>
      <TableCell sx={{ py: "5px" }}>
        {/* <TextField disabled defaultValue={row.unit} select size='small' sx={{ minWidth: 150 }}>
                    {
                        units?.map(unit => <MenuItem value={unit._id}>{unit.name}</MenuItem>)
                    }
                </TextField> */}
        <Typography>
          {units?.find(({ _id }) => row.unit === _id)?.name}
        </Typography>
      </TableCell>
      <TableCell sx={{ py: "5px" }}>
        <TextField value={openingStock} size="small" disabled />
        <input type="hidden" name="openingStock" value={openingStock} />
      </TableCell>
      <TableCell sx={{ py: "5px" }}>
        <TextField
          onChange={(e) => handleItem(e, index)}
          value={row.closingStock}
          size="small"
          name="closingStock"
          required
        />
      </TableCell>
      <TableCell>
        <IconButton onClick={() => removeItem(index)}>
          <CloseIcon></CloseIcon>
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { closeModal } from "../../../../redux/notification/notificationSlice";
import { getOutlets } from "../../../../redux/outlets/outletsSlice";
import {
  getGroups,
  getOffers,
  getSaleProductList,
  getService,
} from "../../../../redux/saleproduct/saleproductSlice";
import { bulkUpdateSales, getSales } from "../../../../redux/sales/salesSlice";
import CustomModal from "../../../common/CustomModal";
import DataTable from "../../../common/DataTable";
import PrimaryButton from "../../../common/PrimaryButton";
import AddSale from "./AddSale";

import AddIcon from "@mui/icons-material/Add";
import currency from "../../../../utils/currencyFormat";
import "../../../dashboard/dashboard.css";
import ActionMenu from "../ActionMenu";
import SalesViewReport from "./SalesViewReport";

const SalesTable = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { sales, total, isLoading, fetch, isBulkUpdating } = useSelector(
    (state) => state.sales
  );
  const { fetch: saleproductFetch, saleproductItemFetch } = useSelector(
    (state) => state.saleproduct
  );
  const [selectedSales, setSelectedSales] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState("");
  const [bulkStatus, setBulkStatus] = useState("");
  const [dateFilter, setDateFilter] = useState({});
  const [selectedSaleProduct, setSelectedSaleProduct] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleDateFilter = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const values = {
      startDate: data.get("startDate"),
      endDate: data.get("endDate"),
    };
    setDateFilter(values);
    dispatch(closeModal());
  };
  useEffect(() => {
    dispatch(getOutlets());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getService({ outlet: id }));
    dispatch(getGroups({ outlet: id }));
    dispatch(getOffers({ outlet: id }));
  }, [dispatch, saleproductItemFetch, id]);

  useEffect(() => {
    dispatch(getSaleProductList({ outlet: id }));
  }, [dispatch, saleproductFetch, id]);

  useEffect(() => {
    const params = {
      page: page,
      size: rowsPerPage,
      paymentType: filter,
      outlet: id,
      ...dateFilter,
    };
    if (!filter) {
      delete params.paymentType;
    }

    dispatch(getSales(params));
  }, [dispatch, page, filter, fetch, id, dateFilter, rowsPerPage]);

  useEffect(() => {
    setPage(1);
  }, [rowsPerPage]);

  const handleFilter = (e) => {
    setFilter(e.target.value);
    setPage(1);
  };

  const handleBulkUpdate = () => {
    dispatch(bulkUpdateSales({ sales: selectedSales, status: bulkStatus }));
    setSelectedSales([]);
  };

  const headings = [
    // {
    //     id: 'salesId',
    //     align: "left",
    //     label: 'Id',
    // },
    {
      id: "date",
      align: "left",
      label: "DATE",
      required: true,
    },
    {
      id: "saleproduct",
      align: "left",
      label: "PRODUCT",
    },
    {
      id: "amount",
      align: "right",
      label: "PRICES (RM)",
    },
    {
      id: "paymentType",
      align: "left",
      label: "PAYMENT TYPE",
    },
    {
      id: "action",
      align: "right",
      label: "Action",
      required: true,
    },
  ];

  return (
    <Box className="space-y-5">
      <Box className="flex justify-end md:hidden">
        <SalesViewReport dateFilter={dateFilter} category="all" />
      </Box>
      <Box className="md:flex md:justify-between space-y-2.5 md:space-y-0 items-center">
        <Box className="flex justify-between items-center md:space-x-5">
          <TextField
            label="Filter Payment Type"
            onChange={handleFilter}
            size="small"
            select
            sx={{ width: 180 }}
            name="paymentType"
          >
            <MenuItem value={""}>All</MenuItem>
            <MenuItem value={"cash"}>Cash</MenuItem>
            <MenuItem value={"card"}>Card</MenuItem>
            <MenuItem value={"prepaid"}>Prepaid</MenuItem>
          </TextField>

          <CustomModal
            sx={{ maxWidth: 300 }}
            title="Date Filter"
            action={
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: "gray",
                  borderColor: "#0000003b",
                  "&:hover": { borderColor: "secondary.main" },
                }}
              >
                Filter Date
              </Button>
            }
          >
            <Box component={"form"} onSubmit={handleDateFilter}>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                Start Date
              </Typography>
              <TextField
                defaultValue={
                  dateFilter.startDate || new Date().toISOString().split("T")[0]
                }
                type="date"
                required
                size="small"
                margin="dense"
                fullWidth
                name="startDate"
              />
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                End Date
              </Typography>
              <TextField
                defaultValue={
                  dateFilter.endDate || new Date().toISOString().split("T")[0]
                }
                type="date"
                required
                size="small"
                margin="dense"
                fullWidth
                name="endDate"
              />
              <PrimaryButton sx={{ mt: 2 }} type="submit">
                Filter
              </PrimaryButton>
            </Box>
          </CustomModal>
          {dateFilter.startDate && (
            <IconButton onClick={() => setDateFilter({})}>
              <Close />
            </IconButton>
          )}
          {selectedSales.length > 0 && (
            <>
              <TextField
                onChange={(e) => setBulkStatus(e.target.value)}
                label="Bulk Action"
                size="small"
                select
                sx={{ width: 180, mx: 1 }}
                name="status"
              >
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"submitted"}>Submitted</MenuItem>
                <MenuItem value={"incorrect-invoice"}>
                  Incorrect Invoice
                </MenuItem>
                <MenuItem value={"delivery-not-fullfil"}>
                  Delivery Not Fullfil
                </MenuItem>
                <MenuItem value={"wrong-outlet"}>Wrong Outlet</MenuItem>
                <MenuItem value={"delete"}>Delete</MenuItem>
              </TextField>
              <PrimaryButton variant="contained" onClick={handleBulkUpdate}>
                Apply
              </PrimaryButton>
            </>
          )}
        </Box>
        <div className="flex justify-end">
          <AddSale
            action={
              <Button startIcon={<AddIcon />} variant="outlined">
                Sale
              </Button>
            }
          />
        </div>
      </Box>
      <DataTable
        height="calc(100vh - 400px)"
        headings={headings}
        rows={sales}
        total={total}
        page={page}
        loading={isBulkUpdating || isLoading}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        updateFields={{
          saleproduct: (item) =>
            (item?.saleproduct?.service?.name &&
              item?.saleproduct?.service?.name + ", ") +
            (item?.saleproduct?.group?.name &&
              item?.saleproduct?.group?.name + ", ") +
            item?.saleproduct?.offerType?.name,
          date: (item) => (
            <>{new Date(item.date).toLocaleDateString("en-MY")}</>
          ),
          amount: (item) => <>{currency.format(item.amount)}</>,
          action: (item) => (
            <ActionMenu
              id={item._id}
              details={item}
              // handleSubmit={addSalesHandler}
              selectedsaleproduct={selectedSaleProduct}
              setSelectedsaleproduct={setSelectedSaleProduct}
              item={item}
              title="Sales"
              edit
            />
          ),
        }}
      />
    </Box>
  );
};

export default SalesTable;

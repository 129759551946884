import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material";
import React from "react";
const MyOutlets = ({ outlets }) => {
  return (
    <Paper
      sx={{
        mt: 2,
        p: 2,
        boxShadow: "0px 0px 51px 5px rgba(0, 0, 0, 0.04)",
        borderRadius: "10px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: 20, fontWeight: 700 }} variant="h4">
          My List of Outlets
        </Typography>
        {/* <IconButton>
                    <VisibilityIcon />
                </IconButton> */}
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>OUTLETS</TableCell>
              <TableCell>CLASS</TableCell>
              <TableCell>SUPPLIERS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {outlets.map((row) => (
              <TableRow
                key={row._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.class}</TableCell>
                <TableCell>{row.suppliers?.length}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default MyOutlets;

import { Autocomplete, Box, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOutlet } from "../../../redux/outlets/outletsSlice";
import PreLoader from "../../common/PreLoader";
import PrimaryButton from "../../common/PrimaryButton";

const AddOutlet = ({
  handleSubmit,
  edit,
  id,
  users = [],
  suppliers = [],
  setSelectedSuppliers,
  setSelectedUsers,
}) => {
  const dispatch = useDispatch();
  const { outletDetails, isDetailsLoading } = useSelector(
    (state) => state.outlets
  );
  useEffect(() => {
    if (edit) {
      dispatch(getSingleOutlet(id));
    }
  }, [edit, id, dispatch]);

  const handleSuppliers = (_e, value) => {
    setSelectedSuppliers(value);
  };
  const handleUsers = (_e, value) => {
    setSelectedUsers(value);
  };

  if (isDetailsLoading) {
    return (
      <Box sx={{ height: "55vh" }}>
        <PreLoader />
      </Box>
    );
  }
  return (
    <Box component={"form"} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
            Assign Users
          </Typography>
          {/* <TextField defaultValue={edit ? outletDetails?.user?._id : ""} required size='small' margin='dense' select fullWidth name='user'>
                        {
                            users.map(({ _id, email }) => (
                                <MenuItem value={_id}>{email}</MenuItem>
                            ))
                        }
                    </TextField> */}
          <Autocomplete
            size="small"
            multiple
            id="tags-outlined"
            onChange={handleUsers}
            options={users}
            getOptionLabel={(option) => option.email}
            defaultValue={
              edit
                ? users?.filter(
                    ({ _id }) => outletDetails?.users?.indexOf(_id) > -1
                  )
                : []
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                placeholder="choose users"
              />
            )}
          />
        </Grid>
        {/* <Grid item md={6} xs={12}>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>Pc. Account</Typography>
                    <TextField
                        defaultValue={edit ? outletDetails?.pcAccount : ""}
                        size="small"
                        margin="dense"
                        name="pcAccount"
                        fullWidth
                        required
                    />
                </Grid> */}
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>Name:</Typography>
          <TextField
            defaultValue={edit ? outletDetails?.name : ""}
            size="small"
            margin="dense"
            name="name"
            type="text"
            fullWidth
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>Class</Typography>
          <TextField
            inputProps={{ maxLength: 3 }}
            defaultValue={edit ? outletDetails?.class : ""}
            size="small"
            margin="dense"
            name="class"
            fullWidth
            // required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontSize: 18, color: "secondary.main" }}>
            Suppliers
          </Typography>
          <Autocomplete
            size="small"
            multiple
            id="tags-outlined"
            onChange={handleSuppliers}
            options={suppliers}
            getOptionLabel={(option) => option.name}
            defaultValue={
              edit
                ? suppliers?.filter(
                    ({ _id }) => outletDetails?.suppliers?.indexOf(_id) > -1
                  )
                : []
            }
            filterSelectedOptions
            renderInput={(params) => (
              <TextField
                {...params}
                margin="dense"
                // label="filterSelectedOptions"
                placeholder="choose supplier"
              />
            )}
          />
        </Grid>
      </Grid>

      <Box sx={{ textAlign: "right", mt: 3 }}>
        <PrimaryButton sx={{ width: 150 }} type="submit">
          {edit ? "Update" : "Add"} Outlet
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default AddOutlet;

import Close from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef } from "react";
import { twoDecimalValidator } from "../../../../utils/twoDecimalValidator";
import PrimaryButton from "../../../common/PrimaryButton";

const AddDeposit = ({ handleSubmit, attachment, setAttachment }) => {
  const attachmentRef = useRef();
  const clearImage = () => {
    attachmentRef.current.value = "";
    setAttachment(null);
  };
  return (
    <Box component={"form"} onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>Date</Typography>
          <TextField
            defaultValue={new Date().toISOString().split("T")[0]}
            type="date"
            required
            size="small"
            margin="dense"
            fullWidth
            name="date"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
            Amount (OUT) (RM)
          </Typography>
          <TextField
            inputProps={{ step: 0.01 }}
            onChange={twoDecimalValidator}
            size="small"
            margin="dense"
            name="amount"
            type="number"
            fullWidth
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
            Bank In Slip No.
          </Typography>
          <TextField
            size="small"
            margin="dense"
            name="bankSlipNo"
            type="number"
            fullWidth
            required
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography sx={{ fontSize: 18, fontWeight: 500, mb: 1 }}>
            Attachment (optional)
          </Typography>
          {/* <Typography sx={{ fontSize: 18, fontWeight: 500, mb: 1 }}>Attachment</Typography> */}
          <Button
            startIcon={<UploadIcon />}
            variant="outlined"
            component="label"
          >
            Upload file
            <input
              hidden
              accept="image/png,image/jpeg,image/jpg,application/pdf"
              type="file"
              name="attachment"
              ref={attachmentRef}
              onChange={(e) => setAttachment(e.target.files[0])}
            />
          </Button>
          {attachment && (
            <Typography>
              {attachment.name}{" "}
              <IconButton onClick={clearImage}>
                <Close />
              </IconButton>
            </Typography>
          )}
        </Grid>
      </Grid>

      <Box sx={{ textAlign: "right", mt: 3 }}>
        <PrimaryButton sx={{ width: 150 }} type="submit">
          Add Deposit
        </PrimaryButton>
      </Box>
    </Box>
  );
};

export default AddDeposit;

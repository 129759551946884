import { axiosTokenInstance } from "../../utils/axios";

export const getAllSales = async (params) => {
  const { data } = await axiosTokenInstance.get("sales/list", {
    params: {
      ...params,
    },
  });
  return data;
};
export const getAllListSales = async (params) => {
  const { data } = await axiosTokenInstance.get("sales/list-all", {
    params: {
      ...params,
    },
  });
  return data;
};
export const getSaleSummary = async (params) => {
  const { data } = await axiosTokenInstance.post("sales/summary", params);
  return data;
};

export const addNewSales = async (payload) => {
  const { data } = await axiosTokenInstance.post("sales/create", payload);
  return data;
};
export const editSales = async (payload) => {
  const { data } = await axiosTokenInstance.post(
    "sales/edit/" + payload.id,
    payload.data
  );
  return data;
};
export const deleteSales = async (id) => {
  const { data } = await axiosTokenInstance.delete("sales/delete/" + id);
  return data;
};

export const bulkUpdate = async (payload) => {
  const { data } = await axiosTokenInstance.patch("sales/bulk-update", payload);
  return data;
};

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Close from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addSaleProduct } from "../../../../redux/saleproduct/saleproductSlice";
import { addSales } from "../../../../redux/sales/salesSlice";
import { twoDecimalValidator } from "../../../../utils/twoDecimalValidator";
import CustomModal from "../../../common/CustomModal";
import PrimaryButton from "../../../common/PrimaryButton";
import CategoryItem from "./CategoryItem";

const AddSale = ({ action }) => {
  const { id } = useParams();
  const [rows, setRows] = useState([]);
  const { modalClose } = useSelector((state) => state.notification);
  const { allSaleProduct, service, groups, offers, fetch } = useSelector(
    (state) => state.saleproduct
  );
  const dispatch = useDispatch();
  const [isCategory, setIsCategory] = useState(false);

  const addRowHandler = (item) => {
    const id = rows.length > 0 ? rows?.[rows.length - 1]?.id + 1 : 1;
    setRows((pre) => [
      ...pre,
      { id, ...item, salesId: nanoid(11), paymentType: "cash" },
    ]);
  };

  const addSalesHandler = (e) => {
    e.preventDefault();
    if (rows.length === 0) return;
    const data = new FormData(e.currentTarget);
    const date = data.get("date");
    const formValue = rows?.map(({ amount, _id, paymentType, salesId }) => ({
      salesId,
      amount,
      paymentType,
      saleproduct: _id,
      date,
      outlet: id,
      type: "in",
    }));
    dispatch(addSales({ sales: formValue }));
  };

  const handleChange = (e, id) => {
    const newRows = rows.map((row) => {
      if (row.id === id) {
        return {
          ...row,
          [e.target.name]: e.target.value,
        };
      } else {
        return { ...row };
      }
    });
    setRows(newRows);
  };
  useEffect(() => {
    setIsCategory(false);
  }, [fetch]);

  useEffect(() => {
    setRows([]);
  }, [modalClose]);

  const removeSelectedCategory = (id) => {
    const restCategory = rows.filter((category) => category.id !== id);
    setRows(restCategory);
  };

  const getTotal = (arr) => {
    return arr.reduce((acc, curr) => acc + Number(curr.amount), 0);
  };

  const addSaleProductHandler = (e) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const data = {
      outlet: id,
      service: form.get("service"),
      group: form.get("group"),
      offerType: form.get("offer"),
    };
    dispatch(addSaleProduct(data));
  };

  return (
    <CustomModal
      sx={{ maxWidth: 1000 }}
      title={isCategory ? "" : "Add Sales"}
      action={action}
    >
      {isCategory ? (
        <Box component={"form"} onSubmit={addSaleProductHandler}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton size="large" onClick={() => setIsCategory(false)}>
              <ChevronLeftIcon />
            </IconButton>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Add New Product
            </Typography>
          </Box>
          <Paper sx={{ p: 2 }} variant="outlined">
            <Typography sx={{ fontSize: 16 }}>
              Step 1. Choose type of services
            </Typography>
            <CategoryItem outlet={id} type="service" />
          </Paper>
          <Paper sx={{ p: 2, mt: 2 }} variant="outlined">
            <Typography sx={{ fontSize: 16 }}>
              Step 2. Choose type of group
            </Typography>
            <CategoryItem outlet={id} type="group" />
          </Paper>
          <Paper sx={{ p: 2, mt: 2 }} variant="outlined">
            <Typography sx={{ fontSize: 16 }}>
              Step 3. Choose type of offer
            </Typography>
            <CategoryItem outlet={id} type="offer" required />
          </Paper>
          <Button
            type="submit"
            sx={{ color: "#fff", mt: 2, mb: 3 }}
            variant="contained"
          >
            Add Product
          </Button>
        </Box>
      ) : (
        <Box component={"form"} onSubmit={addSalesHandler}>
          <Grid container spacing={2}>
            <Grid item md={7} xs={12}>
              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                Date
              </Typography>
              <TextField
                defaultValue={new Date().toISOString().split("T")[0]}
                type="date"
                required
                size="small"
                margin="dense"
                fullWidth
                name="date"
              />
            </Grid>
            <Grid item md={7} xs={12}>
              <Typography sx={{ fontSize: 18, fontWeight: 500, mb: 1 }}>
                Product
              </Typography>
              <TextField value="" size="small" select fullWidth>
                {allSaleProduct.map(({ service, group, offerType, _id }) => (
                  <MenuItem
                    onClick={() =>
                      addRowHandler({ service, group, offerType, _id })
                    }
                    value={_id}
                    key={_id}
                  >{`${service?.name}, ${group?.name}, ${offerType?.name}`}</MenuItem>
                ))}
              </TextField>
              <Button
                onClick={() => setIsCategory(true)}
                size="small"
                sx={{ mt: 1, textTransform: "capitalize" }}
              >
                + Create A New Product{" "}
              </Button>
            </Grid>
            {rows.length > 0 && (
              <Grid item xs={12}>
                <TableContainer>
                  <Table sx={{ "& th,td": { py: 1 } }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>No.</TableCell>
                        <TableCell>Services</TableCell>
                        <TableCell>Group</TableCell>
                        <TableCell>Offer Type</TableCell>
                        <TableCell>Amount (RM)</TableCell>
                        <TableCell>Payment Type</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows?.map(
                        (
                          {
                            _id,
                            service,
                            group,
                            offerType,
                            id,
                            amount,
                            paymentType,
                          },
                          i
                        ) => (
                          <TableRow key={id}>
                            <TableCell>{i + 1}</TableCell>
                            <TableCell>{service.name}</TableCell>
                            <TableCell>{group.name}</TableCell>
                            <TableCell>{offerType.name}</TableCell>
                            <TableCell>
                              <TextField
                                inputProps={{ step: 0.01 }}
                                required
                                size="small"
                                type="number"
                                name="amount"
                                sx={{ width: 80 }}
                                value={amount || ""}
                                onChange={(e) => {
                                  twoDecimalValidator(e);
                                  handleChange(e, id);
                                }}
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                select
                                required
                                size="small"
                                type="number"
                                name="paymentType"
                                sx={{ width: 100 }}
                                value={paymentType || ""}
                                onChange={(e) => handleChange(e, id)}
                              >
                                <MenuItem value="cash">Cash</MenuItem>
                                <MenuItem value="card">Card</MenuItem>
                                <MenuItem value="prepaid">Prepaid</MenuItem>
                              </TextField>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                color="error"
                                onClick={() => removeSelectedCategory(id)}
                              >
                                <Close />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            <Grid item md={7} xs={12}>
              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                Amount (RM)
              </Typography>
              <TextField
                disabled
                value={getTotal(rows || [])}
                size="small"
                margin="dense"
                name="amount"
                type="number"
                fullWidth
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>

          <Box sx={{ textAlign: "right", mt: 3 }}>
            <PrimaryButton sx={{ width: 150 }} type="submit">
              Add Sales
            </PrimaryButton>
          </Box>
        </Box>
      )}
    </CustomModal>
  );
};

export default AddSale;

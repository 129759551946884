import { axiosTokenInstance } from "../../utils/axios";

export const getCashbook = async (params) => {
  const { data } = await axiosTokenInstance.get("cashbook/list", {
    params: {
      ...params,
    },
  });
  return data;
};
export const getAllCashbooks = async (params) => {
  const { data } = await axiosTokenInstance.get("cashbook/list-all", {
    params: {
      ...params,
    },
  });
  return data;
};

export const getSummary = async (params) => {
  const { data } = await axiosTokenInstance.get("cashbook/summary", {
    params: {
      ...params,
    },
  });
  return data;
};

export const removeCashbook = async (id) => {
  const { data } = await axiosTokenInstance.delete("cashbook/delete/" + id);
  return data;
};

export const addNewDeposit = async (payload) => {
  const { data } = await axiosTokenInstance.post("cashbook/create", payload, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return data;
};

import DashboardIcon from "@mui/icons-material/Dashboard";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import InventoryOutlinedIcon from "@mui/icons-material/InventoryOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import StoreMallDirectoryOutlinedIcon from "@mui/icons-material/StoreMallDirectoryOutlined";
import { Box } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import { Link, useLocation } from "react-router-dom";
// import Add from '@mui/icons-material/Add';
import { useSelector } from "react-redux";
import Logout from "../Auth/Logout";
const menuItem = [
  {
    label: "Dashboard",
    Icon: DashboardIcon,
    path: "/dashboard",
  },
  {
    label: "Outlet",
    Icon: StoreMallDirectoryOutlinedIcon,
    path: "/dashboard/outlets",
  },
  {
    label: "Suppliers",
    Icon: Inventory2OutlinedIcon,
    path: "/dashboard/suppliers",
  },
  {
    label: "Inventory list",
    Icon: InventoryOutlinedIcon,
    path: "/dashboard/inventories",
  },
  {
    label: "Users",
    Icon: PeopleAltOutlinedIcon,
    path: "/dashboard/users",
  },
];

const NavigationMenu = ({ open }) => {
  const { pathname } = useLocation();
  const { currentUser } = useSelector((state) => state.auth);
  const [isAdmin, setIsAdmin] = React.useState(false);
  React.useEffect(() => {
    if (
      currentUser?.role === "admin" ||
      currentUser?.role === "readonlyAdmin"
    ) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
  }, [currentUser]);
  return (
    <Box
      sx={{
        "& a": {
          color: "text.primary",
          textDecoration: "none",
        },
        "& .menuItem": {
          marginBottom: "1px",
          "&:hover": {
            color: "secondary.main",
            "& .icon": {
              color: "secondary.main",
            },
          },
          "&.active": {
            color: "secondary.main",
            "& .icon": {
              color: "secondary.main",
            },
          },
        },
      }}
    >
      {/* <Box sx={{ textAlign: "center", my: 3 }}>
                <Button variant="contained" sx={{ color: "#fff" }}><Add /> {open ? "Add New" : ""}</Button>
            </Box> */}
      {menuItem.map(
        ({ label, Icon, path }) =>
          (!isAdmin && label === "Users") || (
            <Link to={path} key={path}>
              <ListItemButton
                sx={{ pl: open ? 6 : 3, transition: "0.3s" }}
                className={`menuItem ${
                  pathname === path
                    ? "active"
                    : pathname.includes(path) && path !== "/dashboard"
                    ? "active"
                    : ""
                }`}
              >
                <ListItemIcon>
                  <Icon className="icon" />
                </ListItemIcon>
                <ListItemText primary={label} />
              </ListItemButton>
            </Link>
          )
      )}
      <Logout open={open} />
    </Box>
  );
};

export default NavigationMenu;

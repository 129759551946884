import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { getPettyCashList } from "../../../../redux/pettycashes/pettycashesSlice";
import currency from "../../../../utils/currencyFormat";
import CustomModal from "../../../common/CustomModal";
import DownloadExcel from "../../../common/DownloadExcel";

const PettyCashViewReport = ({ category, dateFilter }) => {
  const { search } = useLocation();
  const tableRef = useRef(null);
  const { outletDetails } = useSelector((state) => state.outlets);
  const { currentUser } = useSelector((state) => state.auth);
  const page = search?.split("?page=")?.[1];
  const { allPettyCash } = useSelector((state) => state.pettycashes);
  const dispatch = useDispatch();
  useEffect(() => {
    let params = { category, outlet: outletDetails?._id, ...dateFilter };
    if (!category || category === "all") {
      delete params.category;
    }
    dispatch(getPettyCashList(params));
  }, [dispatch, category, dateFilter, outletDetails?._id]);
  const pettyCashTableRef = useRef();
  const pettyCashTableRef2 = useRef();
  const title = document.title;
  const fileName = "PettyCash-" + new Date().toLocaleDateString("en-MY");

  const groupByDate = (arr) =>
    arr.reduce((acc, obj) => {
      const key = obj.date;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(obj);
      return acc;
    }, {});
  const data = groupByDate(allPettyCash) || {};
  const getTotal = (arr) => {
    return arr.reduce((acc, curr) => Number(curr.amount) + acc, 0);
  };

  const excelBody = allPettyCash?.map(
    ({ amount, category, pvc, description, date }) => {
      return {
        pvc,
        date: new Date(date).toLocaleDateString("en-MY"),
        category: category?.name,
        description,
        amount: currency.format(amount),
      };
    }
  );

  const PrintTable = React.forwardRef((props, ref) => {
    return (
      <div ref={ref}>
        <Typography
          variant="h3"
          sx={{
            fontSize: 26,
            fontWeight: 600,
            mb: "5px",
            textTransform: "capitalize",
          }}
        >
          {outletDetails?.name} {page ? `> ${page}` : ""}
        </Typography>
        <Typography sx={{ mb: 1 }}>
          User assigned: {currentUser?.firstName} {currentUser?.lastName}{" "}
        </Typography>
        <table ref={tableRef}>
          <thead>
            <tr>
              <th align="center"> Date </th>
              <th align="center"> Invoice Number </th>
              <th align="center"> Categories </th>
              <th align="center"> Description </th>
              <th align="center"> Amount </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(data)?.map((key) => {
              return (
                <>
                  {data[key]?.map((item, i) => (
                    <tr key={i}>
                      {i === 0 && (
                        <td
                          sx={{ borderRight: "1px solid currentColor" }}
                          align="center"
                          rowSpan={data[key]?.length}
                        >
                          {" "}
                          {new Date(key).toLocaleDateString("en-MY")}{" "}
                        </td>
                      )}
                      <td align="center"> {item.pvc} </td>
                      <td align="center"> {item?.category?.name} </td>
                      <td align="center"> {item?.description} </td>
                      <td align="right"> {currency.format(item?.amount)} </td>
                    </tr>
                  ))}
                  <tr>
                    <td align="center" colSpan={4}>
                      {" "}
                      Total{" "}
                    </td>
                    <td align="right">
                      {" "}
                      {currency.format(getTotal(data[key]))}{" "}
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  });
  return (
    <Box className="flex items-center">
      <CustomModal
        action={<Button startIcon={<VisibilityIcon />}>View Report</Button>}
      >
        <Box
          sx={{
            "& table": {
              width: "100%",
              borderCollapse: "collapse",
              fontFamily: "Inter",
              "& td,th": { border: "1px solid #ddd", p: 1 },
            },
          }}
        >
          <div>
            <PrintTable ref={pettyCashTableRef} />
            <ReactToPrint
              trigger={() => (
                <Button sx={{ mt: 2, color: "#fff" }} variant="contained">
                  Print
                </Button>
              )}
              content={() => pettyCashTableRef.current}
              onBeforePrint={() => (document.title = fileName)}
              onAfterPrint={() => (document.title = title)}
            />

            {/* <DownloadTableExcel
              filename="pettycash"
              sheet="sheet1"
              currentTableRef={tableRef.current}
            >
              <Button
                sx={{ mt: 2, ml: 1, color: "#fff" }}
                variant="contained"
              >
                Download Excel
              </Button>
            </DownloadTableExcel> */}
            <DownloadExcel
              header={[
                "INV NO.",
                "DATE",
                "CATEGORY",
                "DESCRIPTION",
                "AMOUNT (RM)",
              ]}
              body={excelBody || []}
              fileName={fileName}
            />
          </div>
        </Box>
      </CustomModal>
      <>
        <Box sx={{ display: "none" }}>
          <PrintTable ref={pettyCashTableRef2} />
        </Box>
        <ReactToPrint
          trigger={() => (
            <IconButton>
              <PrintIcon />{" "}
            </IconButton>
          )}
          content={() => pettyCashTableRef2.current}
          onBeforePrint={() => (document.title = fileName)}
          onAfterPrint={() => (document.title = title)}
        />
      </>
    </Box>
  );
};

export default PettyCashViewReport;

// import React, { Fragment, useEffect, useRef, useState } from "react";
// import {
//   Box,
//   Button,
//   IconButton,
//   Typography,
//   MenuItem,
//   TextField,
// } from "@mui/material";
// import { useDispatch, useSelector } from "react-redux";
// import ReactToPrint from "react-to-print";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import CustomModal from "../../../common/CustomModal";
// import PrintIcon from "@mui/icons-material/Print";
// import { useLocation } from "react-router-dom";
// import { getMonthStartEnd } from "../../../../utils/getMonthDates";
// import { getSummaryPettyCashList } from "../../../../redux/pettycashes/pettycashesSlice";

// const getMonthlyData = (data, year, month) => {
//   const dates = getMonthStartEnd(year, month);
//   const startDay = new Date(dates.firstDate).getDate();
//   const endDay = new Date(dates.lastDate).getDate();
//   const monthArray = [];
//   for (let i = startDay; i <= endDay; i++) {
//     monthArray.push({ date: i });
//   }
//   const monthlyData = monthArray.map((singleItem) => {
//     const isDataExist = data?.filter(
//       (item) => new Date(item.date).getDate() === singleItem.date
//     );
//     if (!!isDataExist) {
//       return { date: singleItem.date, data: [...isDataExist] };
//     } else {
//       return { date: singleItem.date, data: [{ ...singleItem }] };
//     }
//   });
//   let result = [];
//   for (let i = 0; i < 4; i++) {
//     const week = monthlyData.slice(
//       i * 7,
//       i === 3 ? monthlyData.length : i * 7 + 7
//     );
//     result.push(week);
//   }
//   return result;
// };

// export default function PettyCashViewReport() {
//   const dispatch = useDispatch();
//   const { search } = useLocation();
//   const { outletDetails } = useSelector((state) => state.outlets);
//   const page = search?.split("?page=")?.[1];
//   const { pettyCashSummary, fetch } = useSelector((state) => state.pettycashes);
//   const outletID = outletDetails?._id;
//   const [month, setMonth] = useState(
//     `${new Date().getMonth() + 1}`.padStart(2, "0")
//   );
//   const [year, setYear] = useState(new Date().getFullYear());

//   useEffect(() => {
//     let outlet = outletID;
//     let params = { outlet, year, month };
//     dispatch(getSummaryPettyCashList(params));
//   }, [dispatch, year, month, outletID, fetch]);

//   const pettyCashTableRef = useRef();
//   const pettyCashTableRef2 = useRef();
//   const title = document.title;
//   const fileName = "Petty-cash-" + new Date().toLocaleDateString("en-MY");

//   const groupByDate = (arr) => {
//     const pettyCashWithDate = arr.reduce((acc, obj) => {
//       const key = obj.date;
//       if (!acc[key]) {
//         acc[key] = [];
//       }
//       acc[key].push(...obj.data);
//       return acc;
//     }, {});
//     return pettyCashWithDate;
//   };
//   const groupData = getMonthlyData(pettyCashSummary, year, month) || [];

//   const getTotal = (arr) => {
//     return arr.reduce((acc, curr) => Number(curr.amount) + acc, 0);
//   };

//   const PrintTable = React.forwardRef((props, ref) => {
//     return (
//       <div ref={ref}>
//         <Typography
//           variant="h3"
//           sx={{
//             fontSize: 26,
//             fontWeight: 600,
//             mb: "5px",
//             textTransform: "capitalize",
//           }}
//         >
//           {outletDetails?.name} {page ? `> ${page}` : ""}
//         </Typography>
//         <Typography sx={{ mb: 1 }}>
//           User assigned: {outletDetails?.user?.firstName}{" "}
//           {outletDetails?.user?.lastName}
//         </Typography>
//         <Box sx={{ my: 2 }}>
//           <TextField
//             size="small"
//             value={month}
//             onChange={(e) => setMonth(e.target.value)}
//             sx={{ width: 140 }}
//             select
//           >
//             <MenuItem value="01">January</MenuItem>
//             <MenuItem value="02">February</MenuItem>
//             <MenuItem value="03">March</MenuItem>
//             <MenuItem value="04">April</MenuItem>
//             <MenuItem value="05">May</MenuItem>
//             <MenuItem value="06">June</MenuItem>
//             <MenuItem value="07">July</MenuItem>
//             <MenuItem value="08">August</MenuItem>
//             <MenuItem value="09">September</MenuItem>
//             <MenuItem value="10">October</MenuItem>
//             <MenuItem value="11">November</MenuItem>
//             <MenuItem value="12">December</MenuItem>
//           </TextField>
//           <TextField
//             size="small"
//             value={year}
//             onChange={(e) => setYear(e.target.value)}
//             sx={{ width: 140 }}
//             select
//           >
//             {[...new Array(5).keys()].map((_e, i) => {
//               const year = new Date().getFullYear();
//               return <MenuItem value={year - i}>{year - i}</MenuItem>;
//             })}
//           </TextField>
//         </Box>
//         {pettyCashSummary.length === 0 ? (
//           <Typography variant="h3" sx={{ textAlign: "center", my: 10 }}>
//             No Data Found!
//           </Typography>
//         ) : (
//           <table>
//             <thead>
//               <tr>
//                 <th align="center"> Date </th>
//                 <th align="center"> Inv No. </th>
//                 <th align="center"> Categories </th>
//                 <th align="center"> Description </th>
//                 <th align="center"> Amount </th>
//                 <th align="center"> Total </th>
//               </tr>
//             </thead>
//             <tbody>
//               {groupData?.map((weeklyData, i) => {
//                 const subTotal = weeklyData?.reduce(
//                   (prev, curr) =>
//                     prev +
//                     (curr?.data?.reduce(
//                       (prev, curr) => prev + (curr.amount || 0),
//                       0
//                     ) || 0),
//                   0
//                 );
//                 const data = groupByDate(weeklyData);
//                 return (
//                   <Fragment key={i}>
//                     {Object.keys(data)?.map((key) => {
//                       const total = data[key]?.reduce(
//                         (pre, curr) => pre + (curr.amount || 0),
//                         0
//                       );
//                       return (
//                         <>
//                           {data[key]?.length > 0 ? (
//                             data[key]?.map(
//                               (
//                                 { _id, amount, category, description, pvc },
//                                 i
//                               ) => (
//                                 <tr key={_id}>
//                                   {i === 0 && (
//                                     <td
//                                       sx={{
//                                         borderRight: "1px solid currentColor",
//                                       }}
//                                       align="center"
//                                       rowSpan={data[key]?.length}
//                                     >
//                                       {" "}
//                                       {key}{" "}
//                                     </td>
//                                   )}
//                                   <td align="center"> {pvc} </td>
//                                   <td align="center"> {category?.name} </td>
//                                   <td align="center"> {description} </td>
//                                   <td align="center"> {amount} </td>
//                                   {i === 0 && (
//                                     <td
//                                       align="center"
//                                       rowSpan={data[key]?.length}
//                                     >
//                                       {total}
//                                     </td>
//                                   )}
//                                 </tr>
//                               )
//                             )
//                           ) : (
//                             <tr>
//                               <td align="center"> {key} </td>
//                               <td align="center"> - </td>
//                               <td align="center"> - </td>
//                               <td align="center"> - </td>
//                               <td align="center"> - </td>
//                               <td align="center"> - </td>
//                             </tr>
//                           )}
//                         </>
//                       );
//                     })}
//                     <tr>
//                       <td colSpan={5} align="center">
//                         S/Total
//                       </td>
//                       <th align="center">{subTotal}</th>
//                     </tr>
//                   </Fragment>
//                 );
//               })}
//               <tr>
//                 <td colSpan={5} align="center">
//                   G/Total
//                 </td>
//                 <th align="center">{getTotal(pettyCashSummary)}</th>
//               </tr>
//             </tbody>
//           </table>
//         )}
//       </div>
//     );
//   });
//   return (
//     <Box sx={{ display: "flex" }}>
//       <CustomModal
//         action={<Button startIcon={<VisibilityIcon />}>View Report</Button>}
//       >
//         <Box
//           sx={{
//             "& table": {
//               width: "100%",
//               borderCollapse: "collapse",
//               fontFamily: "Inter",
//               "& td,th": { border: "1px solid #ddd", p: 1 },
//             },
//           }}
//         >
//           <div>
//             <PrintTable ref={pettyCashTableRef} />

//             <ReactToPrint
//               trigger={() => (
//                 <Button sx={{ mt: 2, color: "#fff" }} variant="contained">
//                   Print
//                 </Button>
//               )}
//               content={() => pettyCashTableRef.current}
//               onBeforePrint={() => (document.title = fileName)}
//               onAfterPrint={() => (document.title = title)}
//             />
//           </div>
//         </Box>
//       </CustomModal>
//       <>
//         <Box sx={{ display: "none" }}>
//           <PrintTable ref={pettyCashTableRef2} />
//         </Box>
//         <ReactToPrint
//           trigger={() => (
//             <IconButton>
//               <PrintIcon />{" "}
//             </IconButton>
//           )}
//           content={() => pettyCashTableRef2.current}
//           onBeforePrint={() => (document.title = fileName)}
//           onAfterPrint={() => (document.title = title)}
//         />
//       </>
//     </Box>
//   );
// }

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    addNewStockUsage,
    deleteStockUsageItem,
    editStockUsage,
    getAllUsages,
    getStockSummary,
} from "./stockUsageAPI";

const initialState = {
  stockUsages: [],
  total: 0,
  isLoading: false,
  isError: false,
  fetch: 0,
  summary: [],
};

export const getStockUsages = createAsyncThunk(
  "stocks-usages/list",
  async (params) => {
    const data = await getAllUsages(params);
    return data;
  }
);

export const addStockUsage = createAsyncThunk(
  "stocks-usages/create",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await addNewStockUsage(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateStockUsage = createAsyncThunk(
  "stocks-usages/edit",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await editStockUsage(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteStockUsage = createAsyncThunk(
  "stocks-usages/delete",
  async (id, { rejectWithValue }) => {
    try {
      const data = await deleteStockUsageItem(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStockReport = createAsyncThunk(
  "stocks-usages/summary",
  async (params) => {
    const data = await getStockSummary(params);
    return data;
  }
);

const stockUsageReducer = createSlice({
  name: "stockUsages",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getStockUsages.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(getStockUsages.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.stockUsages = payload.data?.stockUsages;
        state.total = payload.data?.total;
      })
      .addCase(getStockUsages.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(getStockReport.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(getStockReport.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.summary = payload.summary;
      })
      .addCase(getStockReport.rejected, (state, { error }) => {
        state.isLoading = false;
      });
    builder
      .addCase(addStockUsage.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(addStockUsage.fulfilled, (state) => {
        state.isLoading = false;
        state.fetch += 1;
      })
      .addCase(addStockUsage.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(updateStockUsage.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(updateStockUsage.fulfilled, (state) => {
        state.isLoading = false;
        state.fetch += 1;
      })
      .addCase(updateStockUsage.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(deleteStockUsage.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(deleteStockUsage.fulfilled, (state) => {
        state.isLoading = false;
        state.fetch += 1;
      })
      .addCase(deleteStockUsage.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default stockUsageReducer.reducer;

import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import billsSlice from "./bills/billsSlice";
import cashbookSlice from "./cashbook/cashbookSlice";
import dashboardSlice from "./dashboard/dashboardSlice";
import notificationSlice from "./notification/notificationSlice";
import outletsSlice from "./outlets/outletsSlice";
import pettycashesSlice from "./pettycashes/pettycashesSlice";
import productCategorySlice from "./productCategory/productCategorySlice";
import productUnitSlice from "./productUnit/productUnitSlice";
import productsSlice from "./products/productsSlice";
import saleproductSlice from "./saleproduct/saleproductSlice";
import salesSlice from "./sales/salesSlice";
import stockUsageSlice from "./stockUsage/stockUsageSlice";
import stocksSlice from "./stocks/stocksSlice";
import suppliersSlice from "./suppliers/suppliersSlice";
import userSlice from "./users/userSlice";

const reducer = {
  auth: authSlice,
  users: userSlice,
  outlets: outletsSlice,
  bills: billsSlice,
  stocks: stocksSlice,
  stockUsages: stockUsageSlice,
  pettycashes: pettycashesSlice,
  sales: salesSlice,
  cashbooks: cashbookSlice,
  saleproduct: saleproductSlice,
  suppliers: suppliersSlice,
  products: productsSlice,
  categories: productCategorySlice,
  units: productUnitSlice,
  notification: notificationSlice,
  dashboard: dashboardSlice,
};

export const store = configureStore({
  reducer,
  devTools: process.env.NODE_ENV !== "production",
});

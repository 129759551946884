import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, IconButton, ListItemButton, ListItemText } from "@mui/material";
import Popover from "@mui/material/Popover";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { editOutlet } from "../../../redux/outlets/outletsSlice";
import CustomModal from "../../common/CustomModal";
import DeleteConfirmation from "../User/DeleteConfirmation";
import AddOutlet from "./AddOutlet";

const items = ["sales", "purchase", "petty-cash", "stock", "cashbook"];
const labels = ["Sales", "Purchase", "Petty Cash", "Stock", "Cash book"];

export default function PopupMenu({
  id,
  deleteHandler,
  users = [],
  suppliers = [],
  selectedUsers,
  setSelectedUsers,
  selectedSuppliers,
  setSelectedSuppliers,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { currentUser } = useSelector((state) => state.auth);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const ids = open ? "simple-popover" : undefined;
  const dispatch = useDispatch();
  const handleEdit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const users = selectedUsers?.map(({ _id }) => _id);
    const suppliers = selectedSuppliers?.map(({ _id }) => _id);
    const outletData = {
      name: data.get("name"),
      class: data.get("class"),
      pcAccount: data.get("pcAccount"),
      users,
      suppliers,
    };
    dispatch(editOutlet({ id, data: outletData }));
  };
  return (
    <div>
      <IconButton
        aria-describedby={ids}
        variant="contained"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Popover
        id={ids}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ minWidth: 170 }}>
          {/* <Typography sx={{ px: 2, py: 1, fontWeight: 700 }}>Add New</Typography> */}
          {items.map((item, i) => (
            <ListItemButton
              component={Link}
              to={`/dashboard/outlets/${id}?page=${item}`}
              key={`${item[i]}`}
            >
              <ListItemText>{labels[i]}</ListItemText>
            </ListItemButton>
          ))}
          {currentUser?.role === "admin" && (
            <>
              <CustomModal
                title={"Edit outlet"}
                action={
                  <ListItemButton>
                    <ListItemText sx={{ color: "primary.main" }}>
                      Edit Outlet
                    </ListItemText>
                  </ListItemButton>
                }
              >
                <AddOutlet
                  edit
                  id={id}
                  handleSubmit={handleEdit}
                  users={users}
                  suppliers={suppliers}
                  setSelectedUsers={setSelectedUsers}
                  setSelectedSuppliers={setSelectedSuppliers}
                />
              </CustomModal>
              <CustomModal
                title={"Are you sure to delete?"}
                sx={{ maxWidth: 400, borderRadius: "0px" }}
                action={
                  <ListItemButton>
                    <ListItemText sx={{ color: "error.main" }}>
                      Remove List
                    </ListItemText>
                  </ListItemButton>
                }
              >
                <DeleteConfirmation deleteHandler={() => deleteHandler(id)} />
              </CustomModal>
            </>
          )}
        </Box>
      </Popover>
    </div>
  );
}

import { Box, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { editOutlet } from "../../../../redux/outlets/outletsSlice";
import { twoDecimalValidator } from "../../../../utils/twoDecimalValidator";
import PrimaryButton from "../../../common/PrimaryButton";

const AddPettyCashAmount = ({ outlet, pettyCashAmount }) => {
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const amount = formData.get("amount");
    dispatch(editOutlet({ id: outlet, data: { pettyCashAmount: amount } }));
  };

  return (
    <Box component={"form"} onSubmit={handleSubmit}>
      <Typography sx={{ fontSize: 18, fontWeight: 500, mb: 1 }}>
        Amount (RM)
      </Typography>
      <TextField
        fullWidth
        inputProps={{ step: 0.01 }}
        onChange={(e) => {
          twoDecimalValidator(e);
        }}
        defaultValue={pettyCashAmount}
        size="small"
        type="number"
        required
        name="amount"
      />

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
        <Box>
          <PrimaryButton sx={{ width: 150 }} type="submit">
            {pettyCashAmount ? "Edit" : "Add"} petty cash
          </PrimaryButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AddPettyCashAmount;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/Auth/PrivateRoute";
import Notifications from "./components/common/Notifications";
import Dashboard from "./pages/Dashboard";
import DashboardLayout from "./pages/DashboardLayout";
import Inventories from "./pages/Inventories";
import LoginRegister from "./pages/LoginRegister";
import NotFound from "./pages/NotFound";
import OutletItemSingle from "./pages/OutletItemSingle";
import Outlets from "./pages/Outlets";
import Suppliers from "./pages/Suppliers";
import Users from "./pages/Users";
import Verification from "./pages/Verification";
import { disableLoading, loadCurrentUser } from "./redux/auth/authSlice";
import MuiThemeProvider from "./theme/MuiThemeProvider";

export default function App() {
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.auth);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(loadCurrentUser());
    } else {
      dispatch(disableLoading());
    }
  }, [dispatch]);
  return (
    <MuiThemeProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
          <Route path="/login" element={<LoginRegister />} />
          <Route path="/verify-account/:token" element={<Verification />} />
          <Route path="/register" element={<LoginRegister />} />
          <Route
            path="dashboard"
            element={
              <PrivateRoute>
                <DashboardLayout />
              </PrivateRoute>
            }
          >
            <Route index element={<Dashboard />} />
            {(currentUser?.role === "admin" ||
              currentUser?.role === "readonlyAdmin") && (
              <Route path="users" element={<Users />} />
            )}
            <Route path="outlets" element={<Outlets />} />
            <Route path="inventories" element={<Inventories />} />
            <Route path="suppliers" element={<Suppliers />} />
            <Route path="outlets/:id" element={<OutletItemSingle />} />
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        <Notifications />
      </BrowserRouter>
    </MuiThemeProvider>
  );
}

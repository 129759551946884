import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addNewOutlet,
  deleteSingleOutlet,
  getAllOutletList,
  getAllOutlets,
  getOutlet,
  updateOutlet,
} from "./outletAPI";

const initialState = {
  outlets: [],
  outletDetails: {},
  isDetailsLoading: false,
  allOutlets: [],
  total: 0,
  isLoading: false,
  isError: false,
  fetch: 0,
};

export const getOutlets = createAsyncThunk("outlets/list", async (params) => {
  const data = await getAllOutlets(params);
  return data;
});
export const getListOutlets = createAsyncThunk("outlets/list-all", async () => {
  const data = await getAllOutletList();
  return data;
});

export const addOutlet = createAsyncThunk(
  "outlets/create",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await addNewOutlet(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const editOutlet = createAsyncThunk(
  "outlets/edit",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await updateOutlet(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSingleOutlet = createAsyncThunk(
  "outlets/details",
  async (id) => {
    const data = await getOutlet(id);
    return data;
  }
);

export const deleteOutlet = createAsyncThunk(
  "outlets/delete",
  async (id, { rejectWithValue }) => {
    try {
      const data = await deleteSingleOutlet(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const outletReducer = createSlice({
  name: "outlets",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getOutlets.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(getOutlets.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.outlets = payload.data?.outlets;
        state.total = payload.data?.total;
      })
      .addCase(getOutlets.rejected, (state, { error }) => {
        state.isLoading = false;
      });

    builder.addCase(getListOutlets.fulfilled, (state, { payload }) => {
      state.allOutlets = payload.data;
    });

    builder
      .addCase(addOutlet.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(addOutlet.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.fetch += 1;
      })
      .addCase(addOutlet.rejected, (state, { error }) => {
        state.isLoading = false;
      });

    builder
      .addCase(getSingleOutlet.pending, (state) => {
        state.isError = false;
        state.isDetailsLoading = true;
      })
      .addCase(getSingleOutlet.fulfilled, (state, { payload }) => {
        state.isDetailsLoading = false;
        state.outletDetails = payload.outlet;
      })
      .addCase(getSingleOutlet.rejected, (state, { error }) => {
        state.isDetailsLoading = false;
      });

    builder
      .addCase(editOutlet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editOutlet.fulfilled, (state) => {
        state.isLoading = false;
        state.fetch += 1;
        state.outletDetails = {};
      })
      .addCase(editOutlet.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(deleteOutlet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteOutlet.fulfilled, (state) => {
        state.isLoading = false;
        state.fetch += 1;
      })
      .addCase(deleteOutlet.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default outletReducer.reducer;

import { AppBar, Box, Container, Divider, Toolbar } from "@mui/material";
import React from "react";
import Login from "../components/Auth/Login";
import bgImg from "./../assets/images/login/bg.jpg";
import logo from "./../assets/images/logo/Caterpro_logo.svg";
const LoginRegister = () => {
  return (
    <Box
      sx={{
        backgroundImage: `url("${bgImg}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <AppBar
        sx={{ bgcolor: "white", boxShadow: "none", "& img": { maxHeight: 25 } }}
      >
        <Toolbar className="items-center m-auto">
          <img src={logo} alt="" />
        </Toolbar>
        <Divider />
      </AppBar>
      <Container className="!p-5">
        <Box sx={{ pt: "15vh" }}>
          <Login />
        </Box>
      </Container>
    </Box>
  );
};

export default LoginRegister;

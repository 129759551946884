import Close from "@mui/icons-material/Close";
import UploadIcon from "@mui/icons-material/Upload";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedTableHead from "../../../common/EnhancedTableHead";
import TablePaginationActions from "../../../common/TablePaginationActions";
import { disableEditMode } from "./../../../../redux/notification/notificationSlice";

export default function BillsDataTable({
  height = "calc(100vh - 410px)",
  rows = [],
  suppliers,
  headings = [],
  loading,
  columns,
  updateFields = {},
  total,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  attachment,
  setAttachment,
}) {
  const dispatch = useDispatch();
  const [editableIndex, setEditableIndex] = React.useState(-1);

  const paginationPage = parseInt(page) - 1;
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { isEditMode } = useSelector((state) => state.notification);
  const emptyRows = rows.length < rowsPerPage ? rowsPerPage - rows.length : 0;
  const SingleTableRow = ({ row, index }) => {
    // const handleDoubleClick = () => {
    //   setEditableIndex(index);
    //   dispatch(enableEditMode());
    // };
    const clearAttachment = () => {
      setAttachment(null);
    };
    return (
      <TableRow
        hover
        tabIndex={-1}
        // onDoubleClick={handleDoubleClick}
      >
        {headings?.map(({ id, align }) => (
          <TableCell key={id} align={align} sx={{ py: "5px" }}>
            {editableIndex === index && isEditMode ? (
              <>
                {id === "status" ? (
                  <TextField
                    defaultValue={row[id]}
                    required
                    size="small"
                    margin="dense"
                    select
                    sx={{ width: 130 }}
                    name="status"
                  >
                    <MenuItem value={"pending"}>Pending</MenuItem>
                    <MenuItem value={"submitted"}>Submitted</MenuItem>
                    <MenuItem value={"incorrect-invoice"}>
                      Incorrect Invoice
                    </MenuItem>
                    <MenuItem value={"delivery-not-fullfil"}>
                      Delivery Not Fullfil
                    </MenuItem>
                    <MenuItem value={"wrong-outlet"}>Wrong Outlet</MenuItem>
                  </TextField>
                ) : id === "date" ? (
                  <>
                    <TextField
                      defaultValue={row[id]}
                      type="date"
                      required
                      size="small"
                      margin="dense"
                      sx={{ width: 140 }}
                      fullWidth
                      name="date"
                    />
                    <input type="hidden" name="purchaseId" value={row._id} />
                  </>
                ) : id === "supplier" ? (
                  <TextField
                    defaultValue={row?.supplier?._id}
                    required
                    size="small"
                    margin="dense"
                    select
                    sx={{ width: 130 }}
                    name="supplier"
                  >
                    {suppliers.map(({ _id, name }) => (
                      <MenuItem value={_id} key={_id}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : id === "invoice" ? (
                  <TextField
                    defaultValue={row[id]}
                    size="small"
                    margin="dense"
                    name="invoice"
                    type="text"
                    sx={{ width: 100 }}
                    required
                  />
                ) : id === "amount" ? (
                  <TextField
                    defaultValue={row[id]}
                    size="small"
                    margin="dense"
                    name="amount"
                    type="number"
                    inputProps={{ step: 0.01 }}
                    sx={{ width: 100 }}
                    required
                  />
                ) : id === "photo" ? (
                  <>
                    <IconButton
                      color="primary"
                      variant="contained"
                      component="label"
                      sx={{ display: attachment ? "none" : "inherit" }}
                    >
                      <UploadIcon />
                      <input
                        hidden
                        accept="image/png,image/jpeg,image/jpg,application/pdf"
                        type="file"
                        onChange={(e) => setAttachment(e.target.files[0])}
                      />
                    </IconButton>
                    {attachment && (
                      <Typography sx={{ display: "flex" }}>
                        {attachment.name?.slice(0, 10) + "..."}
                        <IconButton onClick={clearAttachment}>
                          <Close />
                        </IconButton>
                      </Typography>
                    )}
                  </>
                ) : id === "action" ? (
                  <Box component={"span"} sx={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      size="small"
                      sx={{ color: "#fff" }}
                      type="submit"
                    >
                      Update
                    </Button>
                    <Button
                      onClick={() => dispatch(disableEditMode())}
                      color="error"
                    >
                      <Close />{" "}
                    </Button>
                  </Box>
                ) : updateFields[id] ? (
                  updateFields[id](row)
                ) : (
                  row[id]
                )}
              </>
            ) : updateFields[id] ? (
              updateFields[id](row)
            ) : (
              row[id]
            )}
          </TableCell>
        ))}
      </TableRow>
    );
  };
  return (
    <Paper sx={{ width: "100%", mb: 2, mt: 3 }} elevation={0}>
      <TableContainer>
        <Table stickyHeader>
          <EnhancedTableHead
            columns={columns}
            headings={headings}
            rowCount={rows.length}
          />
          {loading ? (
            <Box
              sx={{
                position: "absolute",
                top: "40vh",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 2,
              }}
            >
              <CircularProgress />
            </Box>
          ) : rows.length > 0 ? (
            <TableBody>
              {rows?.map((row, index) => (
                <TableRow hover tabIndex={-1} key={index}>
                  {(columns
                    ? headings?.filter(({ id }) => columns?.indexOf(id) > -1)
                    : headings
                  )?.map(({ id, align, details }) => (
                    <TableCell key={id} align={align} sx={{ py: "5px" }}>
                      {id === "sl"
                        ? index + 1 + (page - 1) * rowsPerPage
                        : updateFields[id]
                        ? updateFields[id](row)
                        : row[id]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={headings.length} align="center">
                  <Typography variant="h4">No Records In The List!</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100, { label: "All", value: total }]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={paginationPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        labelRowsPerPage="Rows:"
      />
    </Paper>
  );
}

import {
  Box,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

import * as React from "react";
import EnhancedTableHead from "./EnhancedTableHead";
import TablePaginationActions from "./TablePaginationActions";

export default function DataTable({
  height = "calc(100vh - 320px)",
  rows = [],
  headings = [],
  loading,
  uniqueField,
  detailsPath,
  columns,
  updateFields = {},
  total,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  inventory,
}) {
  // Avoid a layout jump when reaching the last page with empty rows.

  const emptyRows = rows.length < rowsPerPage ? rowsPerPage - rows.length : 0;

  const paginationPage = parseInt(page) - 1;

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <Paper sx={{ width: "100%" }} elevation={0}>
      {/* <TableContainer sx={{ height }}> */}
      <TableContainer>
        <Table stickyHeader>
          <EnhancedTableHead
            columns={columns}
            headings={headings}
            rowCount={rows.length}
          />
          {loading ? (
            <Box
              sx={{
                position: "absolute",
                top: "40vh",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 2,
              }}
            >
              <CircularProgress />
            </Box>
          ) : rows.length > 0 ? (
            <TableBody>
              {rows?.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={index}>
                    {(columns
                      ? headings?.filter(({ id }) => columns?.indexOf(id) > -1)
                      : headings
                    )?.map(({ id, align, details }) => (
                      <TableCell key={id} align={align} sx={{ py: "5px" }}>
                        {id === "sl"
                          ? index + 1 + (page - 1) * rowsPerPage
                          : updateFields[id]
                          ? updateFields[id](row)
                          : row[id]}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell colSpan={headings.length} align="center">
                  <Typography variant="h4">No Records In The List!</Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[
          10,
          25,
          50,
          100,
          { label: "All", value: total ?? 0 },
        ]}
        component="div"
        count={total ?? 0}
        rowsPerPage={rowsPerPage}
        page={paginationPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        labelRowsPerPage="Rows:"
      />
    </Paper>
  );
}

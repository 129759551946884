import PrintIcon from "@mui/icons-material/Print";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {
  getCashbookList,
  getCashbookSummary,
} from "../../../../redux/cashbook/cashbookSlice";
import currency from "../../../../utils/currencyFormat";
import CustomModal from "../../../common/CustomModal";
import DownloadExcel from "../../../common/DownloadExcel";
import PreLoader from "../../../common/PreLoader";
const CashbookViewReport = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const { outletDetails } = useSelector((state) => state.outlets);
  const { currentUser } = useSelector((state) => state.auth);
  const page = search?.split("?page=")?.[1];
  const { cashbookSummary, isSummaryLoading, allCashbooks } = useSelector(
    (state) => state.cashbooks
  );
  const dispatch = useDispatch();
  const [month, setMonth] = useState(
    `${new Date().getMonth() + 1}`.padStart(2, "0")
  );
  const [year, setYear] = useState(new Date().getFullYear());

  useEffect(() => {
    let params = { year, month, outlet: id };
    dispatch(getCashbookSummary(params));
  }, [dispatch, year, month, id]);

  useEffect(() => {
    let params = { outlet: id };
    dispatch(getCashbookList(params));
  }, [dispatch, id]);

  const tableRef = useRef();
  const tableRef2 = useRef();
  const title = document.title;
  const fileName = "CashBook-" + new Date().toLocaleDateString("en-MY");

  const totalCashIn = cashbookSummary?.reduce(
    (acc, curr) => Number(curr.cashIn) + acc,
    0
  );
  const totalCashOut = cashbookSummary?.reduce(
    (acc, curr) => Number(curr.cashOut) + acc,
    0
  );

  const excelBody = allCashbooks?.map(
    ({ date, amount, type, bankSlipNo, attachment }) => {
      return {
        date: new Date(date).toLocaleDateString("en-MY"),
        amountIn: type === "in" ? currency.format(amount) : "",
        amountOut: type === "out" ? currency.format(amount) : "",
        bankSlipNo,
      };
    }
  );
  const PrintTable = React.forwardRef((props, ref) => {
    return (
      <div ref={ref}>
        <Typography
          variant="h3"
          sx={{
            fontSize: 26,
            fontWeight: 600,
            mb: "5px",
            textTransform: "capitalize",
          }}
        >
          {outletDetails?.name} {page ? `> ${page}` : ""}
        </Typography>
        <Typography sx={{ mb: 1 }}>
          User assigned: {currentUser?.firstName} {currentUser?.lastName}
          {outletDetails?.user?.lastName}
        </Typography>

        <table>
          <thead>
            <tr>
              <th align="center"> DATE </th>
              <th align="center"> AMOUNT (IN) (RM) </th>
              <th align="center"> AMOUNT (OUT) (RM) </th>
              <th align="center"> BALANCE </th>
            </tr>
          </thead>
          <tbody>
            {isSummaryLoading ? (
              <PreLoader />
            ) : (
              cashbookSummary?.map(({ _id, cashOut, cashIn }) => {
                if (cashIn === 0 && cashOut === 0) {
                  return null;
                }
                return (
                  <tr key={_id}>
                    <td align="center">
                      {" "}
                      {new Date(_id).toLocaleDateString("en-MY")}{" "}
                    </td>
                    <td align="right"> {currency.format(cashIn)} </td>
                    <td align="right"> {currency.format(cashOut)} </td>
                    <td align="right"> {currency.format(cashIn - cashOut)} </td>
                  </tr>
                );
              })
            )}
          </tbody>
          <tfoot>
            <tr>
              <th>Total</th>
              <th align="right">{currency.format(totalCashIn)}</th>
              <th align="right">{currency.format(totalCashOut)}</th>
              <th align="right">
                {currency.format(totalCashIn - totalCashOut)}
              </th>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  });
  return (
    <Box className="flex items-center">
      <CustomModal
        action={<Button startIcon={<VisibilityIcon />}>View Report</Button>}
      >
        <Box
          sx={{
            "& table": {
              width: "100%",
              borderCollapse: "collapse",
              fontFamily: "Inter",
              "& td,th": { border: "1px solid #ddd", p: 1 },
            },
          }}
        >
          <div>
            <div className="flex space-x-5 mb-5">
              <TextField
                size="small"
                value={month}
                onChange={(e) => setMonth(e.target.value)}
                sx={{ width: 140 }}
                select
              >
                <MenuItem value="01">January</MenuItem>
                <MenuItem value="02">February</MenuItem>
                <MenuItem value="03">March</MenuItem>
                <MenuItem value="04">April</MenuItem>
                <MenuItem value="05">May</MenuItem>
                <MenuItem value="06">June</MenuItem>
                <MenuItem value="07">July</MenuItem>
                <MenuItem value="08">August</MenuItem>
                <MenuItem value="09">September</MenuItem>
                <MenuItem value="10">October</MenuItem>
                <MenuItem value="11">November</MenuItem>
                <MenuItem value="12">December</MenuItem>
              </TextField>
              <TextField
                size="small"
                value={year}
                onChange={(e) => setYear(e.target.value)}
                sx={{ width: 140 }}
                select
              >
                {/* <MenuItem value="2023">2023</MenuItem> */}
                {[...new Array(5).keys()].map((_e, i) => {
                  const year = new Date().getFullYear();
                  return <MenuItem value={year - i}>{year - i}</MenuItem>;
                })}
              </TextField>
            </div>
            <PrintTable ref={tableRef} />
            <ReactToPrint
              trigger={() => (
                <Button sx={{ mt: 2, color: "#fff" }} variant="contained">
                  Print
                </Button>
              )}
              content={() => tableRef.current}
              onBeforePrint={() => (document.title = fileName)}
              onAfterPrint={() => (document.title = title)}
            />
            <DownloadExcel
              header={[
                "DATE",
                "AMOUNT (IN) (RM)",
                "AMOUNT (OUT) (RM)",
                "BANK IN SLIP NO.",
              ]}
              body={excelBody || []}
              fileName={fileName}
            />
          </div>
        </Box>
      </CustomModal>
      <>
        <Box sx={{ display: "none" }}>
          <PrintTable ref={tableRef2} />
        </Box>
        <ReactToPrint
          trigger={() => (
            <IconButton>
              <PrintIcon />{" "}
            </IconButton>
          )}
          content={() => tableRef2.current}
          onBeforePrint={() => (document.title = fileName)}
          onAfterPrint={() => (document.title = title)}
        />
      </>
    </Box>
  );
};

export default CashbookViewReport;

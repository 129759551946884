import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import BillsTable from "./Bills/BillsTable";
import BillsViewReport from "./Bills/BillsViewReport";
import CashbookTable from "./Cashbook/CashbookTable";
import CashbookViewReport from "./Cashbook/CashbookViewReport";
import PettyCashViewReport from "./PettyCashes/PettyCashViewReport";
import PettyCashesTable from "./PettyCashes/PettyCashesTable";
import SalesTable from "./Sales/SalesTable";
import SalesViewReport from "./Sales/SalesViewReport";
import ConsumptionViewReport2 from "./Stocks/ConsumptionViewReport2";
import StocksTable from "./Stocks/StocksTable";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const items = ["sales", "purchase", "petty-cash", "stock", "cashbook"];
const labels = ["Sales", "Purchase", "Petty Cash", "Stock", "Cash book"];

const OutletItems = () => {
  const { search } = useLocation();
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const [dateFilter, setDateFilter] = React.useState({});
  // petty cash
  const [category, setCategory] = React.useState("all");
  React.useEffect(() => {
    if (search) {
      const page = search?.split("?page=")?.[1];
      const index = items.indexOf(page);
      setValue(index >= 0 ? index : 0);
    }
  }, [search]);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Box
        sx={{
          alignItems: "center",
        }}
        className="border-b flex items-center justify-between md:mx-6"
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
        >
          {items.map((item, i) => (
            <Tab
              key={i}
              component={Link}
              to={`/dashboard/outlets/${id}?page=${item}`}
              label={labels[i]}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
        <div className="hidden md:block">
          {" "}
          {value === 0 && (
            <SalesViewReport dateFilter={dateFilter} category={category} />
          )}
          {value === 1 && (
            <BillsViewReport dateFilter={dateFilter} category={category} />
          )}
          {value === 2 && (
            <PettyCashViewReport dateFilter={dateFilter} category={category} />
          )}
          {value === 3 && <ConsumptionViewReport2 />}
          {value === 4 && <CashbookViewReport dateFilter={dateFilter} />}
        </div>
      </Box>

      <TabPanel value={value} index={0} className="">
        <SalesTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <BillsTable />
      </TabPanel>

      <TabPanel value={value} index={2}>
        <PettyCashesTable
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          category={category}
          setCategory={setCategory}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <StocksTable />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CashbookTable />
      </TabPanel>
    </div>
  );
};

export default OutletItems;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
}

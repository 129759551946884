import { axiosTokenInstance } from "../../utils/axios";

export const getAllOutlets = async (params) => {
  const { data } = await axiosTokenInstance.get("outlets/list", {
    params: {
      ...params,
    },
  });
  return data;
};
export const getAllOutletList = async () => {
  const { data } = await axiosTokenInstance.get("outlets/list-all");
  return data;
};
export const addNewOutlet = async (payload) => {
  const { data } = await axiosTokenInstance.post("outlets/create", payload);
  return data;
};

export const updateOutlet = async (payload) => {
  const { data } = await axiosTokenInstance.patch(
    "outlets/edit/" + payload?.id,
    payload?.data
  );
  return data;
};

export const getOutlet = async (id) => {
  const { data } = await axiosTokenInstance.get("outlets/details/" + id);
  return data;
};

export const deleteSingleOutlet = async (id) => {
  const { data } = await axiosTokenInstance.delete("outlets/delete/" + id);
  return data;
};

import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ConsumptionChart from "../components/dashboard/Charts/ConsumptionChart";
import DashboardItemChart from "../components/dashboard/Charts/DashboardItemChart";
import MyOutlets from "../components/dashboard/Dashboard/MyOutlets";
import QuickLinks from "../components/dashboard/Dashboard/QuickLinks";
import Header from "../components/dashboard/Header";
import PageTitle from "../components/dashboard/PageTitle";
import {
  getCurrentUserOutlets,
  getDashboardConsumptionChart,
  getDashboardConsumptionStats,
  getDashboardPurchaseStats,
  getDashboardSalesStats,
  getDashboardStats,
} from "../redux/dashboard/dashboardSlice";
import currency from "../utils/currencyFormat";

const AnalyticsCard = ({
  title,
  amount,
  color = "primary.main",
  isLoading,
  stats,
}) => {
  const getPercentage = (previous, current) => {
    //Prevent previous month is 0 to get Infinity percentage
    const checkisZero = previous === 0 ? current : previous;
    const percentage = (current / checkisZero) * 100;

    return percentage || 0;
  };

  const splitTitle = title.split("-");

  return (
    <Paper
      sx={{
        boxShadow: "0px 0px 51px 5px rgba(0, 0, 0, 0.04)",
        borderRadius: "10px",
        display: "flex",
        alignItems: "center",
      }}
      className="space-x-5 shrink-0 p-4"
    >
      <AnalyticsRoundedIcon
        sx={{ color }}
        className="min-[1100px]:!text-[60px] min-[900px]:!text-[50px] !text-[80px]"
      />
      <Box className="space-y-2">
        <Box className="items-center">
          <Typography className="!font-bold lg:font-normal text-black min-[1100px]:!text-[13px] min-[900px]:!text-[9px] !text-[13px] ">
            {splitTitle[0]}
          </Typography>
          <Typography className="!font-bold lg:font-normal text-black min-[1100px]:!text-[21px] min-[900px]:!text-[16px] !text-[21px] !leading-none">
            {splitTitle[1]}
          </Typography>
        </Box>

        {isLoading ? (
          <Box sx={{ textAlign: "center" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            {title === "Consumption" ? (
              <>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: { xl: 36, xs: 28 },
                    color: "#000",
                    my: "3px",
                  }}
                >
                  {stats.consumption?.currentMonth?.toFixed(2) || 0}%
                </Typography>
                <Typography>
                  {amount?.previousMonth?.toFixed(2) || 0}% last month
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: { xl: 36, xs: 28 },
                    color: "#000",
                    my: "3px",
                  }}
                >
                  RM{currency.format(amount?.currentMonth || 0)}
                </Typography>
                <Typography>
                  {getPercentage(
                    amount?.currentMonth || 0,
                    amount?.previousMonth || 0
                  )?.toFixed(0) || 0}
                  % of last month
                </Typography>
              </>
            )}
          </>
        )}
      </Box>
    </Paper>
  );
};

const Dashboard = () => {
  const { currentUser } = useSelector((state) => state.auth);
  const {
    stats,
    isLoading,
    salesStats,
    purchaseStats,
    consumptionStats,
    consumptionChart,
    outlets,
  } = useSelector((state) => state.dashboard);
  const dispatch = useDispatch();
  const [currentChart, setCurrentChart] = useState("Sales");
  const [currentOutlet, setCurrentOutlet] = useState("All");
  const [chartData, setChartData] = useState([]);
  // default Chart column
  const [selectedRef, setSelectedRef] = useState(3);
  const canViewAllOutlets = currentUser?.role !== "admin";

  useEffect(() => {
    if (currentChart === "Sales") {
      setChartData(salesStats);
    } else if (currentChart === "Purchase") {
      setChartData(purchaseStats);
    } else if (currentChart === "Consumption") {
      setChartData(consumptionStats);
    }
  }, [
    currentChart,
    salesStats,
    purchaseStats,
    consumptionStats,
    currentOutlet,
  ]);

  const handleChangeChart = (e) => {
    setCurrentChart(e.target.value);
  };

  const handleChangeOutlet = (e) => {
    setCurrentOutlet(e.target.value);
    getData(e.target.value);
    setSelectedRef(3);
  };

  useEffect(() => {
    dispatch(getCurrentUserOutlets());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser && outlets.length > 0) {
      const outletId = "All";
      getData(outletId);
      if (currentUser?.role !== "admin") setCurrentOutlet(outletId);
    }
  }, [currentUser, outlets]);

  const getData = (outletId) => {
    if (outletId === "All") outletId = null;
    dispatch(getDashboardStats({ outletId }));
    dispatch(getDashboardSalesStats({ outletId }));
    dispatch(getDashboardPurchaseStats({ outletId }));
    dispatch(getDashboardConsumptionStats({ outletId }));
    dispatch(getDashboardConsumptionChart());
  };

  const chartRef = useRef();

  const today = moment().startOf("day");

  const oneMonthAgo = moment().startOf("day").subtract(1, "months").toDate();
  const halfYearAgo = moment().startOf("day").subtract(6, "months").toDate();
  const oneYearAgo = moment().startOf("day").subtract(1, "year").toDate();

  return (
    <Box>
      <Header>
        <div className="hidden lg:block items-center">
          <PageTitle>
            Welcome {currentUser?.firstName || ""} {currentUser?.lastName || ""}
          </PageTitle>
        </div>
      </Header>

      <Box className="p-8 lg:p-5 space-y-5">
        <Grid item className="lg:hidden">
          <PageTitle>
            Welcome, {currentUser?.firstName || ""}{" "}
            {currentUser?.lastName || ""}
          </PageTitle>
        </Grid>
        <Grid item md={8} xs={12}>
          <Box className="md:flex justify-between space-y-3 items-center">
            <Typography variant="h3">Summary</Typography>
            <div className="flex flex-col md:flex-row md:flex-wrap gap-x-2.5 gap-y-5">
              <div className="hidden justify-between gap-x-2 lg:flex">
                <Button
                  className="w-1/4"
                  variant={selectedRef === 0 ? "contained" : "outlined"}
                  onClick={() => {
                    chartRef.current.chart.zoomX(
                      oneMonthAgo.getTime(),
                      today.toDate().getTime()
                    );
                    setSelectedRef(0);
                  }}
                >
                  1M
                </Button>
                <Button
                  className="w-1/4"
                  variant={selectedRef === 1 ? "contained" : "outlined"}
                  onClick={() => {
                    chartRef.current.chart.zoomX(
                      halfYearAgo.getTime(),
                      today.toDate().getTime()
                    );
                    setSelectedRef(1);
                  }}
                >
                  6M
                </Button>
                <Button
                  className="w-1/4"
                  variant={selectedRef === 2 ? "contained" : "outlined"}
                  onClick={() => {
                    chartRef.current.chart.zoomX(
                      oneYearAgo.getTime(),
                      today.toDate().getTime()
                    );
                    setSelectedRef(2);
                  }}
                >
                  1Y
                </Button>
                <Button
                  className="w-1/4"
                  variant={selectedRef === 3 ? "contained" : "outlined"}
                  onClick={() => {
                    chartRef.current.chart.resetSeries();
                    setSelectedRef(3);
                  }}
                >
                  ALL
                </Button>
              </div>

              <TextField
                className="order-first md:-order-none"
                size="small"
                select
                value={currentOutlet}
                onChange={handleChangeOutlet}
              >
                <MenuItem value="All">All</MenuItem>
                {outlets.map((outlet) => (
                  <MenuItem key={outlet._id} value={outlet._id}>
                    {outlet.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </Box>
        </Grid>
        <div className="grid min-[900px]:grid-cols-3 gap-x-5 space-y-5 min-[900px]:space-y-0">
          <AnalyticsCard
            title="Current Month-Sales"
            isLoading={isLoading}
            amount={stats?.sales}
            color="primary.main"
          />

          <AnalyticsCard
            title="Current Month-Purchases"
            isLoading={isLoading}
            amount={stats?.purchase}
            color="#FF8282"
          />
          <AnalyticsCard
            title="Current Month-Consumption"
            isLoading={isLoading}
            amount={stats?.consumption}
            stats={stats}
            color="#31D8BA"
          />
        </div>
        <Grid container spacing={2}>
          <Grid item md={8} xs={12} className="space-y-5">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
              className="items-center"
            >
              <Typography variant="h3">Analytics</Typography>
              <TextField
                size="small"
                select
                value={currentChart}
                onChange={handleChangeChart}
              >
                <MenuItem value="Sales">Sales</MenuItem>
                <MenuItem value="Purchase">Purchase</MenuItem>
                <MenuItem value="Consumption">Consumption</MenuItem>
              </TextField>
            </Box>
            <div className="flex justify-between gap-x-2 lg:hidden">
              <Button
                className="w-1/4"
                variant={selectedRef === 0 ? "contained" : "outlined"}
                onClick={() => {
                  chartRef.current.chart.zoomX(
                    oneMonthAgo.getTime(),
                    today.toDate().getTime()
                  );
                  setSelectedRef(0);
                }}
              >
                1M
              </Button>
              <Button
                className="w-1/4"
                variant={selectedRef === 1 ? "contained" : "outlined"}
                onClick={() => {
                  chartRef.current.chart.zoomX(
                    halfYearAgo.getTime(),
                    today.toDate().getTime()
                  );
                  setSelectedRef(1);
                }}
              >
                6M
              </Button>
              <Button
                className="w-1/4"
                variant={selectedRef === 2 ? "contained" : "outlined"}
                onClick={() => {
                  chartRef.current.chart.zoomX(
                    oneYearAgo.getTime(),
                    today.toDate().getTime()
                  );
                  setSelectedRef(2);
                }}
              >
                1Y
              </Button>
              <Button
                className="w-1/4"
                variant={selectedRef === 3 ? "contained" : "outlined"}
                onClick={() => {
                  chartRef.current.chart.resetSeries();
                  setSelectedRef(3);
                }}
              >
                ALL
              </Button>
            </div>
            <DashboardItemChart
              chartData={chartData}
              name={currentChart}
              chartRef={chartRef}
            />
            <MyOutlets outlets={outlets} />
          </Grid>
          <Grid item md={4} xs={12}>
            <QuickLinks />
            <ConsumptionChart data={consumptionChart} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Dashboard;

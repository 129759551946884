import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import Header from "../components/dashboard/Header";
import OutletItems from "../components/dashboard/Outlet/OutletItems";
import { getSingleOutlet } from "../redux/outlets/outletsSlice";

const OutletItemSingle = () => {
  const { id } = useParams();
  const { search } = useLocation();
  const { outletDetails } = useSelector((state) => state.outlets);
  const { currentUser } = useSelector((state) => state.auth);
  const page = search?.split("?page=")?.[1];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleOutlet(id));
  }, [dispatch, id]);

  const havePage = page ? ` > ${page}` : "";
  const outletName = outletDetails?.name + havePage;
  return (
    <>
      <Header
        isOutletItem
        userName={currentUser?.firstName + " " + currentUser?.lastName}
        outletName={outletName}
      >
        <div className="hidden lg:block items-center">
          <Typography
            variant="h3"
            sx={{
              fontSize: 26,
              fontWeight: 600,
              mb: 1,
              textTransform: "capitalize",
            }}
          >
            {outletDetails?.name} {page ? `> ${page}` : ""}
          </Typography>
          <Typography>
            User assigned: {currentUser?.firstName} {currentUser?.lastName}
          </Typography>
        </div>
      </Header>
      <OutletItems />
    </>
  );
};

export default OutletItemSingle;

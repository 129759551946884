import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Close from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  addDeposit,
  getCashbooks,
} from "../../../../redux/cashbook/cashbookSlice";
import { closeModal } from "../../../../redux/notification/notificationSlice";
import { getSuppliersList } from "../../../../redux/suppliers/suppliersSlice";
import currency from "../../../../utils/currencyFormat";
import CustomModal from "../../../common/CustomModal";
import DataTable from "../../../common/DataTable";
import PDFViewer from "../../../common/PDFViewer";
import PrimaryButton from "../../../common/PrimaryButton";
import ActionMenu from "../ActionMenu";
import AddDeposit from "./AddDeposit";
import CashbookViewReport from "./CashbookViewReport";

const CashbookTable = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { cashbooks, total, isLoading, fetch } = useSelector(
    (state) => state.cashbooks
  );
  const [attachment, setAttachment] = useState(null);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [dateFilter, setDateFilter] = useState({});

  const handleDateFilter = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const values = {
      startDate: data.get("startDate"),
      endDate: data.get("endDate"),
    };

    setDateFilter(values);
    dispatch(closeModal());
  };

  useEffect(() => {
    dispatch(getSuppliersList());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      page: page,
      size: rowsPerPage,
      outlet: id,
      ...dateFilter,
    };

    dispatch(getCashbooks(params));
  }, [dispatch, page, fetch, id, dateFilter, rowsPerPage]);

  useEffect(() => {
    setPage(1);
  }, [rowsPerPage]);

  const addDepositHandler = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    formData.append("outlet", id);
    formData.append("type", "out");
    dispatch(addDeposit(formData));
  };

  const headings = [
    {
      id: "sl",
      align: "left",
      label: "#",
    },
    {
      id: "date",
      align: "left",
      label: "DATE",
      required: true,
    },
    {
      id: "amount",
      align: "right",
      label: "AMOUNT (IN/OUT) (RM)",
      required: true,
    },
    {
      id: "bankSlipNo",
      align: "left",
      label: "BANK IN SLIP NO.",
    },
    {
      id: "attachment",
      align: "left",
      label: "ATTACHMENT",
    },
    {
      id: "action",
      align: "right",
      label: "Action",
    },
  ];

  return (
    <Box className="space-y-5">
      <Box className="flex justify-end md:hidden">
        <CashbookViewReport dateFilter={dateFilter} category="all" />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CustomModal
            sx={{ maxWidth: 300 }}
            title="Date Filter"
            action={
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: "gray",
                  borderColor: "#0000003b",
                  "&:hover": { borderColor: "secondary.main" },
                }}
              >
                Filter Date
              </Button>
            }
          >
            <Box component={"form"} onSubmit={handleDateFilter}>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                Start Date
              </Typography>
              <TextField
                defaultValue={
                  dateFilter.startDate || new Date().toISOString().split("T")[0]
                }
                type="date"
                required
                size="small"
                margin="dense"
                fullWidth
                name="startDate"
              />
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                End Date
              </Typography>
              <TextField
                defaultValue={
                  dateFilter.endDate || new Date().toISOString().split("T")[0]
                }
                type="date"
                required
                size="small"
                margin="dense"
                fullWidth
                name="endDate"
              />
              <PrimaryButton sx={{ mt: 2 }} type="submit">
                Filter
              </PrimaryButton>
            </Box>
          </CustomModal>
          {dateFilter.startDate && (
            <IconButton onClick={() => setDateFilter({})}>
              <Close />
            </IconButton>
          )}
        </Box>

        <CustomModal
          title={"Add Deposit"}
          action={
            <Button startIcon={<AddIcon />} variant="outlined">
              {" "}
              Deposit
            </Button>
          }
        >
          <AddDeposit
            attachment={attachment}
            setAttachment={setAttachment}
            handleSubmit={addDepositHandler}
          />
        </CustomModal>
      </Box>
      <DataTable
        height="calc(100vh - 350px)"
        headings={headings}
        rows={cashbooks}
        total={total}
        page={page}
        loading={isLoading}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        updateFields={{
          amount: (item) => (
            <Box
              sx={{ color: item.type === "out" ? "red" : "primary.main" }}
              component="span"
            >
              {currency.format(item.amount)}
            </Box>
          ),
          action: (item) => (
            <>
              {item.type === "out" ? (
                <ActionMenu id={item._id} item={item} title="Cashbook" />
              ) : (
                <></>
              )}
            </>
          ),
          date: (item) => (
            <>{new Date(item.date).toLocaleDateString("en-MY")}</>
          ),
          attachment: (item) => (
            <>
              {!!item.attachment ? (
                <CustomModal
                  action={
                    <IconButton color="primary">
                      {item?.attachment?.includes(".pdf") ? (
                        <AttachFileIcon />
                      ) : (
                        <ImageIcon />
                      )}
                    </IconButton>
                  }
                >
                  <Box
                    sx={{
                      "& img": { maxWidth: "100%" },
                    }}
                  >
                    {item?.attachment?.includes(".pdf") ? (
                      <PDFViewer url={item?.attachment} />
                    ) : (
                      <img src={item?.attachment} alt="attachment" />
                    )}
                  </Box>
                </CustomModal>
              ) : (
                <Box
                  component="span"
                  sx={{ display: "inline-block", height: 40 }}
                ></Box>
              )}
            </>
          ),
        }}
      />
    </Box>
  );
};

export default CashbookTable;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addNewBill,
  bulkUpdate,
  getAllBills,
  getAllListBills,
  getBillsSummary,
  updateSingleBill,
  deleteBillItem,
} from "./billsAPI";

const initialState = {
  billsSummary: [],
  bills: [],
  allBills: [],
  billDetails: {},
  isBillsSummaryLoading: false,
  isDetailsLoading: false,
  isBulkUpdating: false,
  total: 0,
  isLoading: false,
  isError: false,
  fetch: 0,
};

export const getBills = createAsyncThunk("bills/list", async (params) => {
  const data = await getAllBills(params);
  return data;
});
export const getBillList = createAsyncThunk(
  "bills/list-all",
  async (params) => {
    const data = await getAllListBills(params);
    return data;
  }
);

export const addBill = createAsyncThunk(
  "bills/create",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await addNewBill(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const updateBill = createAsyncThunk(
  "bills/edit",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await updateSingleBill(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const bulkUpdateBills = createAsyncThunk(
  "bills/bulk-update",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await bulkUpdate(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getBillSummary = createAsyncThunk(
  "bills/summary",
  async (params) => {
    const data = await getBillsSummary(params);
    return data;
  }
);

export const deleteBill = createAsyncThunk(
  "bills/delete",
  async (id, { rejectWithValue }) => {
    try {
      const data = await deleteBillItem(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const outletReducer = createSlice({
  name: "bills",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getBills.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(getBills.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.bills = payload.data?.bills;
        state.total = payload.data?.total;
      })
      .addCase(getBills.rejected, (state, { error }) => {
        state.isLoading = false;
      });
    builder
      .addCase(getBillList.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(getBillList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.allBills = payload.allBills;
      })
      .addCase(getBillList.rejected, (state, { error }) => {
        state.isLoading = false;
      });

    builder
      .addCase(addBill.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(addBill.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.fetch += 1;
      })
      .addCase(addBill.rejected, (state, { error }) => {
        state.isLoading = false;
      });

    builder
      .addCase(updateBill.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(updateBill.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.fetch += 1;
      })
      .addCase(updateBill.rejected, (state, { error }) => {
        state.isLoading = false;
      });

    builder
      .addCase(bulkUpdateBills.pending, (state) => {
        state.isBulkUpdating = true;
      })
      .addCase(bulkUpdateBills.fulfilled, (state, { payload }) => {
        state.isBulkUpdating = false;
        state.fetch += 1;
      })
      .addCase(bulkUpdateBills.rejected, (state, { error }) => {
        state.isBulkUpdating = false;
      });

    builder
      .addCase(getBillSummary.pending, (state) => {
        state.isError = false;
        state.isBillsSummaryLoading = true;
      })
      .addCase(getBillSummary.fulfilled, (state, { payload }) => {
        state.isBillsSummaryLoading = false;
        state.billsSummary = payload.billsSummary;
      })
      .addCase(getBillSummary.rejected, (state) => {
        state.isBillsSummaryLoading = false;
      });

    builder.addCase(deleteBill.fulfilled, (state) => {
      state.fetch += 1;
    });
  },
});

export default outletReducer.reducer;

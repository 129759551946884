import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../components/common/CustomModal";
import DataTable from "../components/common/DataTable";
import MultiSelect from "../components/common/MultiSelect";
import PrimaryButton from "../components/common/PrimaryButton";
import Header from "../components/dashboard/Header";
import PageTitle from "../components/dashboard/PageTitle";
import ActionMenu from "../components/dashboard/Product/ActionMenu";
import AddProduct from "../components/dashboard/Product/AddProduct";
import { getAllCategory } from "../redux/productCategory/productCategorySlice";
import { getAllUnit } from "../redux/productUnit/productUnitSlice";
import {
  addNewProduct,
  getProductsList,
} from "../redux/products/productsSlice";

import AddIcon from "@mui/icons-material/Add";
import PreLoader from "../components/common/PreLoader";
import currency from "../utils/currencyFormat";

const headings = [
  {
    id: "name",
    align: "left",
    label: "PRODUCT",
    required: true,
  },
  {
    id: "unit",
    align: "left",
    label: "UNIT",
  },
  {
    id: "category",
    align: "left",
    label: "CATEGORY",
  },
  {
    id: "price",
    align: "right",
    label: "LAST USED PRICE (RM)",
  },
  {
    id: "action",
    align: "right",
    label: "Action",
    required: true,
  },
];
const localStorageKey = "coolInventoriesTable";
const Inventories = () => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(
    JSON.parse(
      localStorage.getItem(localStorageKey) ||
        '["name", "category", "price","action"]'
    )
  );
  const { products, isLoading, total, fetch } = useSelector(
    (state) => state.products
  );
  const { allProducts, isAllLoading } = useSelector((state) => state.products);
  const { fetch: categoryFetch, categories } = useSelector(
    (state) => state.categories
  );
  const { fetch: unitFetch, units } = useSelector((state) => state.units);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(25);
  useEffect(() => {
    const params = { page: page, size: rowsPerPage, search };
    if (search === "") {
      delete params.search;
    }
    dispatch(getProductsList(params));
  }, [dispatch, page, rowsPerPage, fetch, search]);

  useEffect(() => {
    dispatch(getAllCategory());
    dispatch(getAllUnit());
  }, [dispatch, categoryFetch, unitFetch]);
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const productData = {
      name: data.get("name"),
      category: data.get("category"),
      price: data.get("price"),
      unit: data.get("unit"),
    };
    dispatch(addNewProduct(productData));
  };

  let timeOutId;
  const handleSearch = (e) => {
    if (timeOutId) {
      clearTimeout(timeOutId);
    }
    timeOutId = setTimeout(() => {
      setSearch((e.target.value || "").toUpperCase());
      setPage(1);
    }, 500);
  };

  return (
    <Box>
      <Header>
        <div className="hidden lg:block items-center">
          <PageTitle>Inventory Stock</PageTitle>
        </div>
      </Header>
      <Box className="m-6 space-y-5">
        <Box className="lg:hidden">
          <PageTitle>Inventory Stock</PageTitle>
        </Box>
        <Box className="flex space-x-5 justify-between shrink-0">
          <Box>
            <MultiSelect
              localStorageKey={localStorageKey}
              columns={columns}
              setColumns={setColumns}
              totalColumns={headings}
              className="w-fit max-w-[140px] md:max-w-fit"
            />
            {/* <TextField
              onChange={handleSearch}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {" "}
                    <SearchIcon /> &nbsp;Search
                  </InputAdornment>
                ),
              }}
              sx={{ width: "20%", minWidth: 200, ml: 2 }}
            /> */}
          </Box>
          <CustomModal
            title={"Manage Inventory list"}
            action={
              <PrimaryButton startIcon={<AddIcon />} sx={{ width: 150 }}>
                Add
              </PrimaryButton>
            }
          >
            <AddProduct
              categories={categories}
              units={units}
              handleSubmit={handleSubmit}
            />
          </CustomModal>
        </Box>

        {isLoading ? (
          <PreLoader />
        ) : (
          <DataTable
            height="calc(100vh - 290px)"
            inventory
            columns={columns}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            headings={headings}
            rows={products}
            total={total}
            page={page}
            setPage={setPage}
            loading={isLoading}
            updateFields={{
              category: (item) => item.category?.name,
              unit: (item) => item.unit?.name,
              price: (item) => <>{currency.format(item.price)}</>,
              action: (item) => <ActionMenu product={item} id={item._id} />,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Inventories;

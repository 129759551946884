import { TableCell, TableHead, TableRow } from "@mui/material";

export function EnhancedTableHead(props) {
  const { order, orderBy, headings, columns } = props;

  return (
    <TableHead sx={{ position: "sticky", top: 0, zIndex: 10 }}>
      <TableRow>
        {(columns
          ? headings?.filter(({ id }) => columns?.indexOf(id) > -1)
          : headings
        ).map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align === "center" ? "left" : headCell.align}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.jsx ? (
              headCell.label
            ) : (
              <span dangerouslySetInnerHTML={{ __html: headCell.label }}></span>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export default EnhancedTableHead;

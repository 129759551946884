import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    addNewDeposit,
    getAllCashbooks,
    getCashbook,
    getSummary,
    removeCashbook,
} from "./cashbookAPI";

const initialState = {
  cashbookSummary: [],
  cashbooks: [],
  allCashbooks: [],
  total: 0,
  isLoading: false,
  isSummaryLoading: false,
  isError: false,
  fetch: 0,
};

export const getCashbooks = createAsyncThunk(
  "cashbook/list",
  async (params) => {
    const data = await getCashbook(params);
    return data;
  }
);
export const getCashbookList = createAsyncThunk(
  "cashbook/list-all",
  async (params) => {
    const data = await getAllCashbooks(params);
    return data;
  }
);
export const getCashbookSummary = createAsyncThunk(
  "cashbook/summary",
  async (params) => {
    const data = await getSummary(params);
    return data;
  }
);

export const addDeposit = createAsyncThunk(
  "cashbook/create",
  async (payload, { rejectWithValue }) => {
    try {
      const data = await addNewDeposit(payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteCashbook = createAsyncThunk(
  "cashbook/delete",
  async (id, { rejectWithValue }) => {
    try {
      const data = await removeCashbook(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const cashbookReducer = createSlice({
  name: "cashbook",
  initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getCashbooks.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(getCashbooks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.cashbooks = payload.data?.cashbooks;
        state.total = payload.data?.total;
      })
      .addCase(getCashbooks.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(getCashbookList.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(getCashbookList.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.allCashbooks = payload.allCashbooks;
      })
      .addCase(getCashbookList.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(getCashbookSummary.pending, (state) => {
        state.isError = false;
        state.isSummaryLoading = true;
      })
      .addCase(getCashbookSummary.fulfilled, (state, { payload }) => {
        state.isSummaryLoading = false;
        state.cashbookSummary = payload.cashbookSummary;
      })
      .addCase(getCashbookSummary.rejected, (state) => {
        state.isSummaryLoading = false;
      });

    builder
      .addCase(addDeposit.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(addDeposit.fulfilled, (state) => {
        state.isLoading = false;
        state.fetch += 1;
      })
      .addCase(addDeposit.rejected, (state) => {
        state.isLoading = false;
      });
    builder
      .addCase(deleteCashbook.pending, (state) => {
        state.isError = false;
        state.isLoading = true;
      })
      .addCase(deleteCashbook.fulfilled, (state) => {
        state.isLoading = false;
        state.fetch += 1;
      })
      .addCase(deleteCashbook.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default cashbookReducer.reducer;

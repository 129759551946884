import AddIcon from "@mui/icons-material/Add";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Close from "@mui/icons-material/Close";
import ImageIcon from "@mui/icons-material/Image";
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  bulkUpdateBills,
  getBills,
  updateBill,
} from "../../../../redux/bills/billsSlice";
import { closeModal } from "../../../../redux/notification/notificationSlice";
import { getSuppliersList } from "../../../../redux/suppliers/suppliersSlice";
import currency from "../../../../utils/currencyFormat";
import CustomModal from "../../../common/CustomModal";
import PDFViewer from "../../../common/PDFViewer";
import PrimaryButton from "../../../common/PrimaryButton";
import ActionMenu from "../ActionMenu";
import AddBill from "./AddBill";
import BillsDataTable from "./BillsDataTable";
import BillsViewReport from "./BillsViewReport";

const BillsTable = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { bills, total, isLoading, fetch, isBulkUpdating } = useSelector(
    (state) => state.bills
  );
  const { allSuppliers } = useSelector((state) => state.suppliers);
  const { currentUser } = useSelector((state) => state.auth);
  const { isEditMode, modalClose } = useSelector((state) => state.notification);
  const [attachment, setAttachment] = React.useState(null);
  const [selectedBills, setSelectedBills] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filter, setFilter] = useState("");
  const [bulkStatus, setBulkStatus] = useState("");
  const [dateFilter, setDateFilter] = useState({});

  const handleDateFilter = (e) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    const values = {
      startDate: data.get("startDate"),
      endDate: data.get("endDate"),
    };

    setDateFilter(values);
    dispatch(closeModal());
  };
  // clear attachment after add or edit
  useEffect(() => {
    setAttachment(null);
  }, [isEditMode, modalClose]);

  useEffect(() => {
    dispatch(getSuppliersList());
  }, [dispatch]);

  useEffect(() => {
    const params = {
      page: page,
      size: rowsPerPage,
      status: filter,
      outlet: id,
      ...dateFilter,
    };
    if (!filter) {
      delete params.status;
    }

    dispatch(getBills(params));
  }, [dispatch, page, filter, fetch, id, dateFilter, rowsPerPage]);

  useEffect(() => {
    setPage(1);
  }, [rowsPerPage]);

  const handleFilter = (e) => {
    setFilter(e.target.value);
    setPage(1);
  };

  const handleBulkUpdate = () => {
    dispatch(bulkUpdateBills({ bills: selectedBills, status: bulkStatus }));
    setSelectedBills([]);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allBills = bills.map(({ _id }) => _id);
      setSelectedBills(allBills);
    } else {
      setSelectedBills([]);
    }
  };
  const handleSelect = (e) => {
    if (e.target.checked) {
      setSelectedBills((pre) => [...pre, e.target.value]);
    } else {
      const rest = selectedBills.filter((id) => id !== e.target.value);
      setSelectedBills(rest);
    }
  };
  const handleEdit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    if (attachment) {
      formData.append("attachment", attachment);
    }
    dispatch(updateBill({ id: formData.get("purchaseId"), data: formData }));
  };
  const headings = [
    {
      id: "checkbox",
      align: "left",
      label: (
        <Checkbox
          sx={{ p: "4px" }}
          size="small"
          checked={bills.length === selectedBills.length && bills.length !== 0}
          onChange={handleSelectAll}
          indeterminate={bills.length !== selectedBills.length}
        />
      ),
      jsx: true,
    },

    {
      id: "invoice",
      align: "left",
      label: "INVOICE NUMBER",
    },
    {
      id: "status",
      align: "left",
      label: "STATUS",
      required: true,
    },
    {
      id: "date",
      align: "left",
      label: "DATE",
      required: true,
    },
    {
      id: "supplier",
      align: "left",
      label: "SUPPLIER",
    },
    // {
    //     id: 'product',
    //     align: "left",
    //     label: 'PRODUCT',
    // },
    // {
    //     id: 'quantity',
    //     align: "left",
    //     label: 'QUANTITY',
    // },
    {
      id: "photo",
      align: "center",
      label: "ATTACHMENT",
    },
    {
      id: "amount",
      align: "right",
      label: "AMOUNT",
    },

    {
      id: "action",
      align: "right",
      label: "Action",
    },
  ];

  return (
    <Box>
      <Box className="flex justify-end md:hidden md:mb-0 mb-2">
        <BillsViewReport dateFilter={dateFilter} category="all" />
      </Box>
      <Box className="md:flex md:justify-between space-y-2.5 md:space-y-0 items-center">
        <Box className="flex justify-between items-center md:space-x-5">
          <TextField
            label="Filter Status"
            onChange={handleFilter}
            size="small"
            select
            sx={{ width: 180, mr: 1 }}
            name="status"
          >
            <MenuItem value={""}>All</MenuItem>
            <MenuItem value={"pending"}>Pending</MenuItem>
            <MenuItem value={"submitted"}>Submitted</MenuItem>
            <MenuItem value={"verified"}>Verified</MenuItem>
            <MenuItem value={"incorrect-invoice"}>Incorrect Invoice</MenuItem>
            <MenuItem value={"delivery-not-fullfil"}>
              Delivery Not Fullfil
            </MenuItem>
            <MenuItem value={"wrong-outlet"}>Wrong Outlet</MenuItem>
          </TextField>

          <CustomModal
            sx={{ maxWidth: 300 }}
            title="Date Filter"
            action={
              <Button
                variant="outlined"
                sx={{
                  textTransform: "capitalize",
                  color: "gray",
                  borderColor: "#0000003b",
                  "&:hover": { borderColor: "secondary.main" },
                }}
              >
                Filter Date
              </Button>
            }
          >
            <Box component={"form"} onSubmit={handleDateFilter}>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                Start Date
              </Typography>
              <TextField
                defaultValue={
                  dateFilter.startDate || new Date().toISOString().split("T")[0]
                }
                type="date"
                required
                size="small"
                margin="dense"
                fullWidth
                name="startDate"
              />
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                End Date
              </Typography>
              <TextField
                defaultValue={
                  dateFilter.endDate || new Date().toISOString().split("T")[0]
                }
                type="date"
                required
                size="small"
                margin="dense"
                fullWidth
                name="endDate"
              />
              <PrimaryButton sx={{ mt: 2 }} type="submit">
                Filter
              </PrimaryButton>
            </Box>
          </CustomModal>
          {dateFilter.startDate && (
            <IconButton onClick={() => setDateFilter({})}>
              <Close />
            </IconButton>
          )}
          {selectedBills.length > 0 && (
            <>
              <TextField
                onChange={(e) => setBulkStatus(e.target.value)}
                label="Bulk Action"
                size="small"
                select
                sx={{ width: 180, mx: 1 }}
                name="status"
              >
                <MenuItem value={"pending"}>Pending</MenuItem>
                <MenuItem value={"submitted"}>Submitted</MenuItem>
                {currentUser?.role === "admin" && (
                  <MenuItem value={"verified"}>Verified</MenuItem>
                )}
                <MenuItem value={"incorrect-invoice"}>
                  Incorrect Invoice
                </MenuItem>
                <MenuItem value={"delivery-not-fullfil"}>
                  Delivery Not Fullfil
                </MenuItem>
                <MenuItem value={"wrong-outlet"}>Wrong Outlet</MenuItem>
                <MenuItem value={"delete"}>Delete</MenuItem>
              </TextField>
              <PrimaryButton variant="contained" onClick={handleBulkUpdate}>
                Apply
              </PrimaryButton>
            </>
          )}
        </Box>
        <div className="flex justify-end">
          <CustomModal
            title={"Add Purchase"}
            action={
              <Button startIcon={<AddIcon />} variant="outlined">
                Purchase
              </Button>
            }
          >
            <AddBill suppliers={allSuppliers} paramId={id} />
          </CustomModal>
        </div>
      </Box>
      {/* <Box component={"form"} onSubmit={handleEdit}> */}
      <BillsDataTable
        suppliers={allSuppliers}
        headings={headings}
        rows={bills}
        total={total}
        page={page}
        loading={isBulkUpdating || isLoading}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        setPage={setPage}
        attachment={attachment}
        setAttachment={setAttachment}
        updateFields={{
          supplier: (item) => item.supplier?.name,
          date: (item) => (
            <>{new Date(item.date).toLocaleDateString("en-MY")}</>
          ),
          amount: (item) => <>{currency.format(item.amount)}</>,
          status: (item) => (
            <Box
              component={"span"}
              sx={{
                color:
                  item.status === "pending"
                    ? "text.primary"
                    : item.status === "verified"
                    ? "primary.main"
                    : item.status === "submitted"
                    ? "orange"
                    : "red",
              }}
            >
              {item.status}
            </Box>
          ),
          photo: (item) => (
            <>
              {!!item.photo ? (
                <CustomModal
                  action={
                    <IconButton color="primary">
                      {item?.photo?.includes(".pdf") ? (
                        <AttachFileIcon />
                      ) : (
                        <ImageIcon />
                      )}
                    </IconButton>
                  }
                >
                  <Box
                    sx={{
                      "& img": { maxWidth: "100%" },
                    }}
                  >
                    {item?.photo?.includes(".pdf") ? (
                      <PDFViewer url={item?.photo} />
                    ) : (
                      <img src={item?.photo} alt="attachment" />
                    )}
                  </Box>
                </CustomModal>
              ) : (
                <Box
                  component="span"
                  sx={{ display: "inline-block", height: 40 }}
                ></Box>
              )}
            </>
          ),
          checkbox: (item) => (
            <Checkbox
              sx={{ p: "4px" }}
              size="small"
              value={item._id}
              checked={selectedBills.indexOf(item._id) > -1 ? true : false}
              onChange={handleSelect}
            />
          ),
          action: (item) => (
            <ActionMenu
              id={item._id}
              details={item}
              item={item}
              title="Purchase"
              edit
            />
          ),
        }}
      />
      {/* </Box> */}
    </Box>
  );
};

export default BillsTable;
